<!-- eslint-disable vue/no-dupe-v-else-if -->
<template>
  <div class="table-wrapper">
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
          <b-col>
            <h4 class="float-left">
              {{ options.title }}
            </h4>
            <slot name="fullDataLink" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>{{ options.subtitle }}</b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading" :opacity="0.5" spinner-variant="secondary" rounded="sm">
          <slot v-if="data.length === 0 && serverOptions.filter.length === 0" name="afterFilter" />
          <div v-if="data.length === 0 && serverOptions.filter.length === 0" class="p-3">
            No data available
          </div>

          <div id="card">
            <v-client-table :key="componentKey" v-if="componentKey || serverOptions.filter.length > 0" ref="table"
              :data="dataSet" :columns="columns" :name="name" :options="ct_options" @row-click="onRowClick($event)"
              @loaded="onLoad" @filter="onFilter" @sorted="onSort" @limit="onLimit" @pagination="onPagination">
              <!-- it was afterFilter before the upgrade from v1.6 to v2.3. Now slot name is beforeLimit-->
              <slot name="afterFilter" slot="beforeLimit" />

              <!-- SETTINGS -->
              <div slot="afterLimit" class="form-row d-flex justify-content-end" v-if="!options.hideSettingsBar">
                <!--
                  broken after update from v1.6 to v2.3.4
-->
                <b-form-select v-if="mode === 'server'" v-model="serverOptions.pagination.rowsPerPage"
                  :options="serverOptions.pagination.perPageValues" @change="onServerRowsPerPageChanged" />

                <b-dropdown variant="outline" :dropleft="true" no-flip no-caret>
                  <template #button-content>
                    <font-awesome-icon v-if="allColumnsVisible" icon="cog" />

                    <font-awesome-icon v-if="!allColumnsVisible" icon="tasks" />
                  </template>

                  <b-form-checkbox class="ml-2" v-for="(item, index) in columnObjects" v-model="item.visible"
                    :key="`${uid}-${index}`" :id="`${uid}-${index}`" :name="`cb-column-${uid}-${index}`" :value="true"
                    :unchecked-value="false" @change="onColumnSettingsChange(item)">
                    {{ item.column }}
                  </b-form-checkbox>
                </b-dropdown>
              </div>

              <!-- CHECK ALL SLOT -->
              <div slot="filter__#" class="d-flex justify-content-center align-items-center">
                <b-form-checkbox v-model="selectAll" @change="onSelectAllChange" />
              </div>

              <!-- DEFAULT-->
              <!--
              <slot
                v-for="roColumnName in readOnlyColumns"
                :name="roColumnName"
                :slot="roColumnName"
                slot-scope="{ row }"
              >
                <div
                  :key="`${$helpers.uuidv4()}`"
                  :class="`w-long`"
                >
                  {{ row[roColumnName] }}
                </div>
              </slot>
-->
              <!-- INLINE EDITING -->
              <slot v-for="columnName in options.editableColumns" :name="columnName" :slot="columnName"
                slot-scope="{row, update, setEditing, isEditing, revertValue}">
                <div :key="`${$helpers.uuidv4()}`" @click.stop="handleEditing(row, columnName, setEditing, revertValue)"
                  v-if="!isEditing() || (options.readOnly && !isInserting)"
                  :class="` ${!options.readOnly ? 'editable-cell' : ''}`"  :style=" isDateColumn(columnName) ? 'width: 11em !important;':''"  >
                  <span v-if="Array.isArray(row[columnName])">{{ dropdownOptionsToString(row[columnName]) }}</span>

                  <span v-else-if="row[columnName]?.url">
                    <b-link :href="row[columnName].url" target="_blank">{{ row[columnName].label }}</b-link></span>
                  <span v-else>{{ row[columnName] }}</span>
                </div>

                <div :key="`${$helpers.uuidv4()}`" class="editing-cell-container "
                  v-if="isEditing() && (!options.readOnly || isInserting)">

                  <input :id="`${$helpers.uuidv4()}`" v-if="
                    isEditing() &&
                    (isStringColumn(columnName) || isNumericColumn(columnName)) &&
                    !isDropdownColumn(columnName) &&
                    !isMaskedColumn(columnName)
                  " class="form-control flex-grow-1 task-name-input" type="text" v-model="row[columnName]"
                    @input="onColumnUpdate(row[ct_options.uniqueKey], columnName, row[columnName])" />
                  <masked-input :id="`${$helpers.uuidv4()}`" v-if="isEditing() && isMaskedColumn(columnName)"
                    :mask="isMaskedColumn(columnName).mask" :placeholder="isMaskedColumn(columnName).placeholder"
                    class="form-control flex-grow-1 task-name-input " type="text" v-model="row[columnName]"
                    @input="onColumnUpdate(row[ct_options.uniqueKey], columnName, row[columnName])" />

                  <date-range-picker-custom v-if="isEditing() && isDateColumn(columnName)" v-model="row[columnName]"
                    :single-date-picker="true" :ranges="false" :init-empty="false"
                    @input="onColumnUpdate(row[ct_options.uniqueKey], columnName, row[columnName])" />

                  <inline-time-picker v-if="isEditing() && isTimeColumn(columnName)"
                    :id="`${$helpers.uuidv4()}-${$helpers.str2_(columnName)}`" v-model="row[columnName]"
                    :readonly="false" :mode="$constants.FORMCONTROLMODE.EDIT"
                    @input="onColumnUpdate(row[ct_options.uniqueKey], columnName, row[columnName])" />
                  <!-- 20220107 :taggable="true" -->
                  <inline-select-multiple v-if="isEditing() && isDropdownColumn(columnName)"
                    :id="`${$helpers.uuidv4()}-${$helpers.str2_(columnName)}`"
                    :value="getDropdownValue(row, columnName)" :readonly="false"
                    :options="getDropdownOptions(columnName)" :allow-empty="true"
                    :mode="$constants.FORMCONTROLMODE.EDIT" :required="false"
                    @changed="onChangeDropdownColumn(columnName, row, ...arguments)" @open="onTableDropdownOpen"
                    @close="onTableDropdownClose" class="ct-inline-select" :custom-option-template="true">
                    <template v-slot:singleLabel="slotProps">
                      <component v-if="getDropdownTemplates(columnName)?.singleLabel" :is="{
                        template: getDropdownTemplates(columnName).singleLabel,
                        props: ['option']
                      }" :option="slotProps.slotScope.option" />
                      <slot v-else name="singleLabel" :slotScope="slotProps.slotScope" />
                    </template>

                    <template v-slot:option="slotProps">
                      <component v-if="getDropdownTemplates(columnName)?.option" :is="{
                        template: getDropdownTemplates(columnName).option,
                        props: ['option']
                      }" :option="slotProps.slotScope.option" />
                      <slot v-else name="option" :slotScope="slotProps.slotScope" />
                    </template>
                  </inline-select-multiple>

                  <div v-if="!isRowEditing(row) && isEditing() && (!options.readOnly || isInserting)"
                    class="inline-buttons-container">
                    <!-- SAVE INLINE TEXT -->
                    <span class="btn btn-success btn-sm" @click.stop="
                        if (validateCell(columnName, row[columnName])) {
                      $emit('inline-update', {
                        id: row[ct_options.uniqueKey],
                        column: columnName,
                        value: row[columnName]
                      })
                      update(row[columnName])
                      setEditing(false)
                    }
                      " v-if="!isNewRecord(row) && !isDropdownColumn(columnName)">
                      <font-awesome-icon icon="check" />
                    </span>
                    <!-- SAVE INLINE DROPDOWN -->
                    <span class="btn btn-success btn-sm" @click.stop="
                        if (validateCell(columnName, row[columnName])) {
                      $emit('inline-update', {
                        id: row[ct_options.uniqueKey],
                        column: columnName,
                        value: getDropdownValue(row, columnName)
                      })
                      update(row[columnName])
                      setEditing(false)
                    }
                      " v-if="!isNewRecord(row) && isDropdownColumn(columnName)">
                      <font-awesome-icon icon="check" />
                    </span>
                    <span class="btn btn-danger btn-sm" @click.stop="
                      revertValue()
                    setEditing(false)
                      " v-if="!isNewRecord(row)">
                      <font-awesome-icon icon="ban" />
                    </span>
                  </div>
                </div>
              </slot>

              <!-- CUSTOM COLUMNS -->
              <slot v-for="slotName in options.slots" :name="slotName" :slot="slotName" slot-scope="props"
                :row="props.row" />

              <!-- CHECKBOX -->
              <div name="checkbox" slot="#" slot-scope="props" v-show="options.selectableRows" style="width:100%"
                class="d-flex align-items-center justify-content-center default-cell">
                <b-form-checkbox :id="`${uid}-${name}-cb-row-selector-${props.index}`"
                  :key="`${uid}-${name}-cb-row-selector-${props.index}-${selectedRows?.length}`"
                  :name="`cb-row-selector-${props.index}`" class="ml-1 row-selector" v-model="props.row['#']"
                 >
                 <!--  @change="onRowSelectorChange(props)"-->
                </b-form-checkbox>
              </div>

              <!-- FORMAT COLUMNS -->
              <template v-for="(column, index) in options.formatColumns" :slot="column.name" slot-scope="props">
                <span v-if="column.style" :key="`cc-${index}`">
                  {{ (parseFloat(props.row[column.name]) || 0).toLocaleString('en-US', column.style) }}
                </span>
                <!-- if style is not defined then display value as is -->
                <!-- 20201114 added >> && !column.url -->
                <span v-if="!column.style && !column.url" :key="`cc-${index}`">
                  {{ props.row[column.name] }}
                </span>
                <span v-if="column.url" :key="`cu-${index}`">
                  <b-link :href="props.row[column.name].url" target="_blank">{{ props.row[column.name].label }}</b-link>
                </span>
              </template>

              <!-- DEFAULT ACTIONS BUTTONS-->
              <div v-if="options.showActions" name="default-actions" :slot="options.showActions ? 'Actions' : ''"
                slot-scope="props" class="default-cell">
                <div class="btn-group">
                  <button class="btn btn-primary btn-sm" @click.stop="viewItem(props.row.ID)">
                    <font-awesome-icon icon="eye" />
                  </button>
                  <button class="btn btn-success btn-sm" @click.stop="editItem(props.row.ID)">
                    <font-awesome-icon icon="pencil-alt" />
                  </button>
                  <button class="btn btn-danger btn-sm" @click.stop="deleteItem(props.row.ID)">
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
              <!--DEFAULT ACTIONS BUTTONS - INLINE INSERT -->

              <div :slot="options.showActions ? 'Actions' : ''" v-else-if="options.showActions && props.row.isInserting"
                slot-scope="props" class="default-cell">
                <b-button-group>
                  <button class="btn btn-success btn-sm" @click.prevent="saveNewRecord(props.row)"
                    :disabled="props.row.isLoading">
                    <font-awesome-icon v-if="!props.row.isLoading" icon="save" />
                    <b-spinner v-if="props.row.isLoading" small type="grow" />
                  </button>
                  <button :disabled="props.row.isLoading" class="btn btn-danger btn-sm"
                    @click.stop="revertNewRecord(props.row)">
                    <font-awesome-icon icon="ban" />
                  </button>
                </b-button-group>
              </div>

              <!-- CUSTOM ACTIONS BUTTONS-->
              <slot v-if="options.showCustomActions && !props.row.isInserting && !isRowEditing(props.row)"
                name="custom-actions" :slot="options.showCustomActions ? 'Actions' : ''" slot-scope="props"
                :row="props.row">
              </slot>
              <!--CUSTOM ACTIONS BUTTONS - INLINE INSERT -->
              <div :slot="options.showCustomActions ? 'Actions' : ''"
                v-else-if="options.showCustomActions && props.row.isInserting" slot-scope="props" class="default-cell">
                <b-button-group>
                  <button class="btn btn-success btn-sm" @click.stop="saveNewRecord(props.row)"
                    :disabled="props.row.isLoading">
                    <font-awesome-icon v-if="!props.row.isLoading" icon="save" />
                    <b-spinner v-if="props.row.isLoading" small type="grow" />
                  </button>
                  <button :disabled="props.row.isLoading" class="btn btn-danger btn-sm"
                    @click.stop="revertNewRecord(props.row)">
                    <font-awesome-icon icon="ban" />
                  </button>
                </b-button-group>
              </div>
              <!--CUSTOM ACTIONS BUTTONS - INLINE EDIT -->
              <div :slot="options.showCustomActions ? 'Actions' : ''"
                v-else-if="options.showCustomActions && isRowEditing(props.row)" slot-scope="props"
                class="default-cell">
                <b-button-group>
                  <button class="btn btn-success btn-sm" @click.stop="saveEditedRecord(props.row)"
                    :disabled="props.row.isLoading">
                    <font-awesome-icon v-if="!props.row.isLoading" icon="save" />
                    <b-spinner v-if="props.row.isLoading" small type="grow" />
                  </button>
                  <button :disabled="props.row.isLoading" class="btn btn-danger btn-sm"
                    @click.stop="revertEditedRecord(props.row)">
                    <font-awesome-icon icon="ban" />
                  </button>
                </b-button-group>
              </div>
              <!-- CHILD ROWS -->
              <slot v-if="options.showChildRows" name="child_row" :slot="options.showChildRows ? 'child_row' : ''"
                slot-scope="props" :row="props.row" />

              <!-- AUTOTOTALS ROWS -->

              <tr slot="appendBody" class="VueTables__row auto-totals">
                <td :key="`td-${index}`" v-for="(item, index) in columnObjects.filter(c => c.visible)" tabindex="0"
                  class="font-weight-bold" :style="{ width: item.width, minWidth: item.width }">
                  {{ `${item.total.label} ${item.total.value}` }}
                </td>
              </tr>
              <tr v-if="showOverallTotals" slot="appendBody" class="VueTables__row auto-totals">
                <td colspan="100%">Overall totals:</td>
              </tr>

              <tr v-if="showOverallTotals" slot="appendBody" class="VueTables__row auto-totals">
                <td :key="`td-${index}`" v-for="(item, index) in columnObjects.filter(c => c.visible)" tabindex="0"
                  class="font-weight-bold" :style="{ width: item.width, minWidth: item.width }">
                  {{ `${item.total.label} ${item.total.overallValue}` }}
                </td>
              </tr>
              <!-- AUTOTOTALS ROWS END-->

              <div slot="afterTable" class="pb-3">
                <b-pagination v-if="mode === 'server'" v-model="serverOptions.pagination.currentPage" limit="10"
                  :total-rows="serverOptions.pagination.totalRows" :per-page="serverOptions.pagination.rowsPerPage"
                  @page-click="onServerPagination" />

                <div v-for="(item, index) in totals" :key="`tt-${index}`">
                  <strong>Total {{ item.alias }}:</strong>
                  {{ item.value.toLocaleString('en-US', item.style) }}
                </div>
              </div>
            </v-client-table>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import CryptoJS from 'crypto-js'

import DateRangePickerCustom from '@/components/DateRangePickerCustom'

import InlineSelectMultiple from '@/components/InlineSelectMultiple'

import { mapState } from 'vuex'
import MaskedInput from 'vue-masked-input'

export default {
  name: 'TableCustom',
  components: { DateRangePickerCustom, InlineSelectMultiple, MaskedInput },
  props: {
    name: {
      type: String,
      default: 'table_custom_default_name',
      required: true
    },
    mode: {
      type: String,
      default: 'client'
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function () {
        return {
          showEmpty: false,
          perPage: 50,
          disablePerPageDropdown: false,
          hideUniqueKey: false,
          showChildRowToggler: false,
          hideSettingsBar: false,
          filterByColumn: true,
          explicitColumnTypes: [] // [{column: '', type: ''}]
        }
      }
    }
  },
  //EXTENDED OPTIONS EXAMPLE
  /*
       options:{
               columns: [''] //if columns are not defined then they will be fetched from the backend response. Columns with underscores in names will be hidden
               filterable: ['ID', 'Product Name','Category', 'Qty/Unit','Unit Price','Comission Rate','Usage Unit','Qty in Stock'],
               formatColumns: [
                           {name: 'Unit Price', style:{ style: 'currency', currency: 'USD' }, type: "number"}, //describe type [number|date|month|string] to enable custom sorting, otherwise will work custom sorting based on data (not stable)
                           {name: 'Qty/Unit', style:{ style: 'decimal'}},
                           {name: 'Comission Rate', style:{ style: 'decimal'}},
                           {name: 'Qty in Stock', style:{ style: 'decimal'}},
                           {name: 'Document', url: true}, !!! COLUMN MUST BE AN OBJECT {label: '_text_', url:'_url_'}
                           ],
               totalColumns: [
                   {name: 'Count', alias: 'Count',method: 'count', style:{style: 'decimal'}},
                   {name: 'Spent', style:{style: 'currency', currency: 'USD'}},
                   {name:'Bottles',style: {style: 'decimal'},formula: '#javascript code on the totals#'}],
               autoTotals: false  // will calculate rows count and sum of values for all numeric columns
               footerHeadings: false,
               perPage: 50,
               disablePerPageDropdown: true,
               showActions: false,
               showCustomActions: true,  //slot
               showChildRows: true, //slot
               hideViewAction: true,
               hideEditAction: true,
               hideDeleteAction: true,
               selectableRows: true,
               footer: true,
               skin:"table-sm table-striped table-bordered table-hover w-100",
               }
    */
  data: function () {
    return {
      componentKey: '',
      preventComponentUpdate: false,
      debug: false,
      isInserting: false,
      settingsState: undefined,
      dataSet: [],
      // columns: this.options.columns,
      columnObjects: [],
      totals: [],
      selectAll: false,
      selectedRows: [],
      perPageSelectedValue: undefined,
      //client-table options
      ct_options: {
        uniqueKey: this.options.uniqueKey || 'ID',
        editableColumns: this.options.editableColumns || [],
        showChildRowToggler: this.options.showChildRowToggler,
        //filterByColumn: this.options.filterable ? true : false,
        //filterable: this.options.filterable,
        cellClasses: this.options.cellClasses,
        pagination: this.mode === 'server' ? false : this.options.footer ? this.options.footer : false,
        perPage: this.options.perPage ? this.options.perPage : 100,
        /*
        perPageValues:
          this.mode === "server" ||
          !(this.options.perPage && !this.options.disablePerPageDropdown)
            ? []
            : [10, 25, 50, 100, 500, 1000],
*/
        perPageValues: [10, 25, 50, 100, 500],
        alwaysShowPerPageSelect: true,
        columnsDropdown: false,
        highlightMatches: true,
        saveState: true,
        storage: 'session',
        //skin: this.options.skin,
        skin: 'table-sm table-striped table-bordered table-hover w-100',
        footerHeadings: this.options.footerHeadings ? this.options.footerHeadings : false,
        resizableColumns: false,
        summary: 'summary',
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa'
        },
        texts: {
          count: 'Showing {from} to {to} of {count} records|{count} records|One record',
          first: 'First',
          last: 'Last',
          filter: '',
          filterPlaceholder: 'Search query',
          limit: '',
          page: 'Page:',
          noResults: 'No records to display',
          filterBy: 'Filter by {column}',
          //filterBy: "",
          loading: 'Loading...',
          defaultOption: 'Select {column}',
          columns: ''
        }
      },
      serverOptions: {
        pagination: {
          currentPage: 1,
          totalRows: 1,
          rowsPerPage: this.options.perPage || 10,
          perPageValues: [10, 25, 50, 100, 500]
        },
        sort: {
          ascending: true,

          column: ''
        },
        filter: []
      },
      deferredUpdateTimerId: undefined,
      isTableDropdownOpened: false

    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),

    columns() {
      return this.columnObjects.filter(item => item.visible).map(item => item.column)
    },
    readOnlyColumns() {
      return this.columns.filter(
        item =>
          this.options.editableColumns &&
          !this.options.editableColumns.includes(item) &&
          (this.options.slots ? !this.options.slots.includes(item) : true)
      )
    },
    allColumnsVisible() {
      return this.columnObjects.filter(c => c.visible).length === this.columnObjects.length
    },
    nameHash() {
      return CryptoJS.MD5(this.name).toString()
    },
    isInsertingMode() {
      return this.dataSet.length > 0 && this.dataSet.filter(i => i.isInserting === true).length > 0
    },
    showOverallTotals() {
      return this.mode !== 'server' && this.dataSet.length > this.getPerPageSelectedValue()
    }
  },
  beforeCreate() { },
  beforeMount() { },

  beforeDestroy() {
    // Clean up event listener
    window.removeEventListener('resize', this.handleResize);
  },
  destroyed() { },
  async created() {
    !this.debug || this.$injectLogging(this.name, this)

    sessionStorage.removeItem(`vuetables_${this.name}`)

    await this.readState()

    this.fixFilterQuery()

    this.fixEmptyPerPage()

    if (this.mode === 'server') {
      this.fixDuplicatedPagination()
    }


  },
  async mounted() {
    this.$emit('mounted')
    window.addEventListener('resize', this.handleResize);
  },
  beforeUpdate() { },
  updated() {
    if (this.$refs.table) {
      this.fixEmptyPerPageValue()

      this.highlightFilters()

      if (this.mode === 'server') {
        let limit = document.getElementsByClassName('VueTables__limit-field')
        if (limit && limit.length > 0) limit[0].style.display = 'none'
      }
    }
  },
  methods: {
    // Debounced resize handler to prevent excessive recalculations
    handleResize: _.debounce(function () {
      this.calculateColumnWidths();
    }, 250),
    handleEditing(row, columnName, setEditing) {
      console.log('TableCustom.handleEditing', row, columnName, setEditing)
      
      if (this.options.readOnly) return

      const editing = this.$refs.table.$children[0].editing
      const currentRowEditing = editing.filter(i => i.id === row[this.ct_options.uniqueKey])

      currentRowEditing.forEach(edit => {
        if (edit.column !== columnName) {
          const idx = editing.findIndex(e => e.id === edit.id && e.column === edit.column)
          if (idx > -1) {
            editing.splice(idx, 1)
          }
        }
      })

      setEditing(true)

 
    },
    highlightFilters() {
      let filters = document.querySelectorAll('.VueTables__filters-row input')
      filters.forEach(i => {
        i.value !== '' ? i.classList.add('highlight') : i.classList.remove('highlight')
      })
    },
    fixEmptyPerPageValue() {


      console.log('fixEmptyPerPageValue')
      //Current issue example: previous dataset has selected 1000 records per page. Value stored in session.
      //Current dataset has 80 records and max value in dropdown is 100. As a result it shows empty value
      //because values greater then 100 were hidden by vue-tables-2 component
      //following code fixes it:

      //get stored value from session storage
      const perPageSelectedValue = this.getPerPageSelectedValue()

      //if selected value greater then max possible value in the dropdown then set value to latest value in dropdown
      //...suppose the list is ordered ascending in settings. Get element greater then dataset length or the last one in the list
      const latestValue = (() => {
        if (!this.ct_options.perPageValues.length) return 0

        for (const el of this.ct_options.perPageValues) {
          if (el > this.dataSet.length) return el
        }

        //or return last one
        return this.ct_options.perPageValues[this.ct_options.perPageValues.length - 1]
      })()

      //300125 reset pagination if lower then new dataset length
      if (this.dataSet.length < perPageSelectedValue) {
        let state = sessionStorage.getItem(`vuetables_${this.name}`)
        if (state) {
          state = JSON.parse(state)
          state.page = 1
          sessionStorage.setItem(`vuetables_${this.name}`, JSON.stringify(state))
          if (this.$refs.table) {
            this.$refs.table.setPage(1)
          }
        }
      }

      if (this.$refs.table && !perPageSelectedValue) {
        this.$refs.table.setPage(1)
      }

      if (this.$refs.table && perPageSelectedValue && perPageSelectedValue > latestValue) {
        this.$refs.table.setLimit(latestValue)
      }
    },
    getButtonPosition() {
      return {
        top: '-35px',
        left: '0'
      }
    },
    onSelectAllChange(value) {

      for (let i = 0; i < this.dataSet.length; i++) {
        let row = this.dataSet[i]

        row['#'] = value

        // Vue.set(this.dataSet, i, row)
        this.$set(this, 'dataSet', [...this.dataSet]);

        if (value) this.selectedRows.push(row)
      }

      if (!value) this.selectedRows = []

      this.$emit('row-select', {})
    },
    onSelectAllChange_270225(value) {
      // Get filtered data from the table component
      const filteredData = this.$refs.table.$refs.table.filteredData;

      // Clear selected rows if unchecking
      if (!value) {
        this.selectedRows = [];
      } else {
        this.selectedRows = [...filteredData];
      }

      // Update the checkbox state for each visible row
      for (let i = 0; i < this.dataSet.length; i++) {
        let row = this.dataSet[i];

        // Check if this row is in the filtered data
        const isVisible = filteredData.some(
          filteredRow => filteredRow[this.ct_options.uniqueKey] === row[this.ct_options.uniqueKey]
        );

        //  if (isVisible) {
        // Update checkbox state for visible rows
        row['#'] = value;
        //  }

        Vue.set(this.dataSet, i, row);
      }

      this.$emit('row-select', { allSelected: value, rows: this.selectedRows });
    },
    setVisibleColumns() { },
    toggleChildRow(id) {
      if (this.$refs.table) this.$refs.table.toggleChildRow(id)
    },
    onLoad() { },
    onFilter() {
      //ref.table.ref - important after update from 1.6 to 2.3.4
      const refTable = this.$refs.table.$refs.table

      if (this.mode === 'server') {
        //remove empty filters
        let query = []
        Object.keys(refTable.query).forEach(k => {
          if (refTable.query[k] !== '') {
            query.push({
              name: k,
              value: refTable.query[k]
            })
          }
        })

        this.serverOptions.sort = this.settingsState.orderBy
        this.serverOptions.filter = query

        let payload = {
          pagination: this.serverOptions.pagination,
          sort: this.serverOptions.sort,
          filter: this.serverOptions.filter
        }

        this.$emit('server-filter', payload)
      } else {
        //20211222 it seems working now
        /*
        // as of this writing, the `filter` event fires before the data is filtered so we wait to access the filtered data
        setTimeout(() => {
          //ref.table.ref - important after update from 1.6 to 2.3.4
          const refTable = this.$refs.table.$refs.table;

          this.updateTotals(refTable.filteredData, event);

          this.saveState();

          this.$emit("filter", event);
        }, 250);
        */

        this.updateTotals()
        this.saveState()
      }
    },
    onLimit() {
      this.updateTotals()

      this.saveState()
    },
    onPagination() {
      this.updateTotals()
      this.saveState()
    },
    onSort(event) {
      this.updateTotals()

      this.saveState()

      if (this.mode === 'server') {
        if (event.column === '#') return
        this.serverOptions.sort = {}
        this.serverOptions.sort.ascending = event.ascending
        this.serverOptions.sort.column = event.column

        let payload = {
          pagination: this.serverOptions.pagination,
          sort: this.serverOptions.sort,
          filter: this.serverOptions.filter
        }

        //console.log("OnSort", payload);

        this.$emit('sort', payload)
      }
    },
    setTotalPaginationRows(value) {
      this.serverOptions.pagination.totalRows = value
    },
    onServerPagination(e, page) {
      if (this.mode === 'server') {
        this.serverOptions.pagination.currentPage = page
        this.serverOptions.sort = this.settingsState.orderBy

        let payload = {
          pagination: this.serverOptions.pagination,
          sort: this.serverOptions.sort,
          filter: this.serverOptions.filter
        }

        this.$emit('pagination', payload)
      }
    },
    onServerRowsPerPageChanged(value) {
      if (this.mode === 'server') {
        this.serverOptions.pagination.rowsPerPage = value

        this.serverOptions.sort = this.settingsState.orderBy

        let payload = {
          pagination: this.serverOptions.pagination,
          sort: this.serverOptions.sort,
          filter: this.serverOptions.filter
        }

        this.fixDuplicatedPagination()
        console.log('onServerRowsPerPageChanged', payload)

        this.$emit('pagination', payload)
      }
    },
    fixDuplicatedPagination() {
      let state = sessionStorage.getItem(`vuetables_${this.name}`)

      state = JSON.parse(state)

      //to avoid auto displaying pagination in server mode
      if (this.mode === 'server')
        state.perPage = 500

      if (!state.orderBy) state.orderBy = { column: false }

      sessionStorage.setItem(`vuetables_${this.name}`, JSON.stringify(state))
    },
    fixEmptyPerPage() {
      let state = sessionStorage.getItem(`vuetables_${this.name}`)

      state = JSON.parse(state)

      if (!state.perPage) {
        sessionStorage.setItem(`vuetables_${this.name}`, JSON.stringify(state))
        state.perPage = '10'
      }
    },
    onRowClick_240225(event) {
      this.$emit('row-click', event)

      //20210706
      //this.$emit("view-item", event.row[this.ct_options.uniqueKey]);
    },
    onRowClick(event) {
      
      const e = event?.event

      console.log('onRowClick.e')
      console.log('onRowClick.e?.target?.tagName', e?.target?.tagName)
      console.log('onRowClick.e?.target?.id', e?.target?.id)
    
    const isCheckboxClick = typeof e?.target?.id === 'string' && e?.target?.id.includes('cb-row-selector');
    const isValidCellClick = e?.target?.tagName === "DIV" && e?.target?.classList?.contains('default-cell');
  
    if (!(isCheckboxClick || isValidCellClick)) {
    return;
    }
      console.log('onRowClick2');

      //this.toggleRowSelection(event.row)
      this.onRowSelectorChange(event)



      this.$emit('row-click', event);

    },
    toggleRowSelection(row) {
      // First check if row is already in selectedRows
      const isSelected = this.selectedRows.some(
        item => item[this.ct_options.uniqueKey] === row[this.ct_options.uniqueKey]
      );

      // Toggle selection state based on current state
      if (isSelected) {
        // Remove from selectedRows
        this.selectedRows = this.selectedRows.filter(
          i => i[this.ct_options.uniqueKey] !== row[this.ct_options.uniqueKey]
        );
        // Update checkbox state
        //   this.$nextTick(() => {  Vue.set(row, '#', false); });

      } else {
        // Add to selectedRows
        this.selectedRows.push(row);
        // Update checkbox state
        //   this.$nextTick(() => {  Vue.set(row, '#', true); });

      }
      const index = this.dataSet.findIndex(r => r[this.ct_options.uniqueKey] === row[this.ct_options.uniqueKey]);
      //this.$set(this, 'dataSet', [...this.dataSet]);
      // this.dataSet.splice(index, 1, { ...row, '#': !row['#'] });

      this.$emit('row-select', { row: row, selected: row['#'] });
    },
    onRowSelectorChange(e) {
      e.row['#'] = !e.row['#']
      if (e.row['#'] === true) {
        this.selectedRows.push(e.row)

      }

      if (e.row['#'] === false) {
        this.selectedRows = this.selectedRows.filter(
          i => i[this.ct_options.uniqueKey] !== e.row[this.ct_options.uniqueKey]
        )

      }
      this.$set(this, 'dataSet', [...this.dataSet]);
      console.log('onRowSelectorChange', e.row['#'])
      this.$emit('row-select', e)
    },
    getSelectedRows() {
      return [...this.selectedRows]
    },
    async drawTable(data) {
      if (!data.length) {
        //20210924
        this.dataSet = data
        return
      }

      let columns = []

      //if columns were not set then update them dynamically
      if (!this.options.columns || this.options.columns.length === 0) {
        let o = data.find(o => o !== {})

        delete o.__ob__

        columns = Object.getOwnPropertyNames(o)

        //hide columns with underscores
        columns = columns.filter(n => !n.includes('_'))
      } else {
        columns = [...this.options.columns]
      }

      if (this.options.selectableRows) columns.unshift('#')


      if (this.options.showActions) columns.push('Actions')

      //20220104
      //this.initColumnObjects(data, columns);

      this.dataSet = data;

      this.initColumnObjects(data, columns)

      this.selectedRows = []

      setTimeout(() => {
        //$refs.table.filteredData is updating with delay, so totals should be calulated with a small lag
        //in the inserting mode update should be skipped

        if (!this.isInsertingMode) {
          this.updateTotals()
          this.updateComponentKey()
        }
      }, 250)
    },
    refresh() {
      //provoke vue-tables-2 component update
      this.componentKey = ''

      this.drawTable(this.dataSet)
    },
    updateTotals() {
      this.updateAutoTotals()
    },
    async readState() {
      this.settingsState = await this.$api.get(`users/${this.profile.data.id}/customtable/${this.nameHash}/settings`)

      if (JSON.stringify(this.settingsState) === '{}' || JSON.stringify(this.settingsState) === '[]') return

      sessionStorage.setItem(`vuetables_${this.name}`, this.settingsState)

      this.settingsState = JSON.parse(this.settingsState)

      return
    },
    fixFilterQuery() {
      let state = sessionStorage.getItem(`vuetables_${this.name}`)

      //fix - vue-tables-2: Unable to set filter. Filter value must be an object (`filterByColumn` is set to `true`) >>> query = {} important!
      if (!state && this.ct_options.filterByColumn)
        state =
          '{"page": "1","query": {},"orderBy": {"111":111,"column": false,"ascending": true},"perPage": "10","customQueries": {}}'

      if (!state && !this.ct_options.filterByColumn)
        state =
          '{"page": "1","query": "","orderBy": {"222":222,"column": false,"ascending": true},"perPage": "10","customQueries": {}}'

      state = JSON.parse(state)

      //try to fix .column undefined error
      //if (!state.page) state.page = 1;
      if (!state.orderBy) state.orderBy = { column: false }

      //161024 If orderBy column not exists in the columns list then set it to false (Avoid future SQL errors)

      if (state.orderBy && state.orderBy.column && !this.options.columns.find(c => c === state.orderBy.column)) {
        /*
        console.log(
          'orderBy column not exists in the columns list',
          this.options.columns,
          state.orderBy
        )
        */
        state.orderBy.column = false

        this.saveState()
      }

      //Empty array error fix
      if (!state.perPage) state.perPage = '10'

      sessionStorage.setItem(`vuetables_${this.name}`, JSON.stringify(state))

      //alernative way but requres $refs
      //this.$refs.table.setFilter({})
    },
    getPerPageSelectedValue() {
      //await this.readState();
      let state = sessionStorage.getItem(`vuetables_${this.name}`)
      state = JSON.parse(state)

      return state ? state.perPage : 0
    },
    saveState() {
      let state = sessionStorage.getItem(`vuetables_${this.name}`)

      if (!state) state = '{}'

      state = JSON.parse(state)

      state.query = {}

      state.columns = this.columnObjects.map(c => ({
        column: c.column,
        visible: c.visible
      }))

      let payload = {
        name: this.name,
        data: JSON.stringify(state)
      }

      this.$api
        .put(`users/${this.profile.data.id}/customtable/${this.nameHash}/settings`, payload)
        .then()
        .catch(e => {
          this.$form.makeToastError(e)
        })

      //save list of ID to session storage to enable Prev/Next buttons in form components
      if (this.$refs.table) {
        let filteredData = this.$refs.table.allFilteredData
        if (filteredData.length > 0 && filteredData[0][this.ct_options.uniqueKey]) {
          let ids = filteredData.map(i => i[this.ct_options.uniqueKey])

          sessionStorage.setItem(`vuetables_${this.name}_keys`, JSON.stringify(ids))
        }

        if (this.mode === 'server') {
          sessionStorage.setItem(`vuetables_${this.name}_server_options`, JSON.stringify(this.serverOptions))
        }
      }
    },
    onColumnSettingsChange() {
      this.saveState()
    },
    logColumnStatistics() {
      const visibleColumns = this.columnObjects.filter(col => col.visible);

      console.log('=== Column Analysis ===');
      console.log('Column | AvgLen | Weight | Width');
      console.log('--------|---------|---------|-------');

      visibleColumns.forEach(col => {
        console.log(
          `${col.column.slice(0, 15).padEnd(15)} | ` +
          `${(col.contentWeight || 0).toFixed(1).padEnd(7)} | ` +
          `${(col.contentWeight || 0).toFixed(1).padEnd(7)} | ` +
          `${parseInt(col.width) || 0}px`
        );
      });
    },
    disableSorting() {
  //Reset the sorting state
  let state = sessionStorage.getItem(`vuetables_${this.name}`);
  
  if (state) {
    state = JSON.parse(state);
    
    // Reset the sorting state
    state.orderBy = { column: false, ascending: true };
    
    // Save the state
    sessionStorage.setItem(`vuetables_${this.name}`, JSON.stringify(state));
    
    console.log('Table sorting has been disabled');
  }
  
  // if the table component is available, reset the internal sorting state
  if (this.$refs.table) {
    // Reset the sorting state
    if (this.$refs.table.$refs.table) {
      this.$refs.table.$refs.table.sortable = {};
    }
    
    // Refresh the table
    this.componentKey = this.$helpers.uid8();
  }
  
  return this;
},
    calculateColumnWidths() {
return
      console.log("==== Calculating column widths ====");
      const visibleColumns = this.columnObjects.filter(col => col.visible);
      const tableContainer = document.querySelector('.VueTables--client');
      const containerWidth = (tableContainer?.offsetWidth - tableContainer?.offsetWidth * 0.05 || 1000);

      const currentPageData = this.dataSet.slice(0, 100);
      const ABSOLUTE_MIN_WIDTH = 10;
      const ABSOLUTE_MAX_WIDTH = Math.floor(containerWidth * 0.5);

      console.log("Container Width:", containerWidth);
      // Calculate total content length for each column
      visibleColumns.forEach(col => {
        const totalLength = currentPageData.reduce((sum, row) => {
          const value = row[col.column];
          return sum + (value ? String(value).replace(/[\s\-0]/g, '').length : 0);
        }, 0);

        let weight = totalLength;
        let multiplier = 1;
        // console.log("Column", col.column, "Total Length", totalLength);


        if (["ID", "Created By", "Created Date", "Updated By", "Modified By", "Actions"].includes(col.column)) {
          weight = 1;
        }

        /*
        if (col.column.includes('Product')) {
          multiplier = 2;
        }
        if (col.column.includes('Account')) {
          multiplier = 3;
        }
          */

        // Consider header length multiplied by record count
        //   weight = Math.max(weight, col.column.length * 100);



        col.contentWeight = weight*multiplier;
        console.log("Column:", col.column, "Length:", totalLength, "Weight:", col.contentWeight);


      });

      // Calculate initial widths
      const totalWeight = visibleColumns.reduce((sum, col) => sum + col.contentWeight, 0);
      let totalCalculatedWidth = 0;
      const initialWidths = {};

      visibleColumns.forEach(col => {
        const proportion = col.contentWeight / totalWeight;
        let pixelWidth = Math.floor(containerWidth * proportion);

        pixelWidth = Math.min(Math.max(pixelWidth, typeMinWidth), ABSOLUTE_MAX_WIDTH);
        initialWidths[col.column] = pixelWidth;
        totalCalculatedWidth += pixelWidth;
      });

      console.log("== Total Calculated Width:", totalCalculatedWidth);
      console.log("== Container Width:", containerWidth);
      // Adjust if total width exceeds container
      if (totalCalculatedWidth > containerWidth) {
        const reductionFactor = containerWidth / totalCalculatedWidth * 0.7;

        visibleColumns.forEach(col => {
          let adjustedWidth = Math.floor(initialWidths[col.column] * reductionFactor);

          col.width = `${adjustedWidth}px`;
        });
      } else {
        visibleColumns.forEach(col => {
          col.width = `${initialWidths[col.column]}px`;
        });
      }

// Override widths for date columns
visibleColumns.forEach(col => {
    if (col.type === 'date') {
      initialWidths[col.column] = 160; // 10em equivalent in pixels
    }
  });

  const table = document.querySelector('.VueTables__table');
  if (table && containerWidth) {
    table.style.width = `${containerWidth}px`;
  }
  
  // Apply column widths using the working method
   //  this.applyColumnWidthsWithVDeep(visibleColumns, initialWidths);


    },


    applyColumnWidthsWithVDeep(columns, widths) {
      // Generate a style element if it doesn't exist
      let styleEl = document.getElementById('dynamic-column-styles');
      if (!styleEl) {
        styleEl = document.createElement('style');
        styleEl.id = 'dynamic-column-styles';
        document.head.appendChild(styleEl);
      }

      // Build CSS rules
      let cssRules = '';
      columns.forEach((col, index) => {
        const width = widths[col.column];

        // Target the td elements directly using the data-label attribute
        cssRules += `
      .VueTables__table td[data-label="${col.column}"] {
        width: ${width}px !important;
        min-width: ${width}px !important;
        max-width: ${width}px !important;
      }
      
      .VueTables__table th:nth-child(${index + 1}) {
        width: ${width}px !important;
        min-width: ${width}px !important;
        max-width: ${width}px !important;
      }
    `;
      });

      // Update style element content
      styleEl.textContent = cssRules;
      console.log("Applied column widths targeting td elements directly");
    },
    initColumnObjects(data, columns) {
      this.columnObjects = []

      const hiddenColumns = this.options.hiddenColumns ? this.options.hiddenColumns : []

      //detect data types
      // eslint-disable-next-line
      for (const [index, column] of columns.entries()) {
        let value = ''
        //find first not empty value
        if (!['#', 'Actions'].includes(column)) {
          value = data.map(row => row[column]).reduce(result => (result ? result : ''))
        }

        let type = this.$helpers.parseType2(value)

        //check explicit  and override if exists
        let ext = this.options.explicitColumnTypes
          ? this.options.explicitColumnTypes.find(i => i.column == column)
          : undefined

        if (ext) {
          type = ext.type
        }

        this.columnObjects.push({
          column: column,
          type: type,
          visible: hiddenColumns.includes(column) ? false : true,
          total: {
            label: '',
            value: '',
            overallValue: ''
          }
        })

        continue
      }

      this.updateColumnsVisibility()

      this.applyCustomSorting()

      this.calculateColumnWidths();

      //20201111
      if (this.options.filterByColumn || this.options.filterByColumn === undefined) {
        //20211107
        this.ct_options.filterByColumn = true

        this.ct_options.filterable = this.columns.filter(c => !['#', 'Actions'].includes(c))
      } else {
        this.ct_options.filterByColumn = false
        this.ct_options.filterable = false
      }

      this.updateComponentKey()
    },
    updateComponentKey() {
      //provoke the vue-tables-2 component initialization. Important for dashboards with multiple tables!
      //this.componentKey = `custom-table-${this.uid}`;

      this.componentKey = `custom-table-${this.$helpers.uid8()}`
    },
    updateColumnsVisibility() {
      let self = this

      if (self.settingsState && self.settingsState.columns) {
        self.settingsState.columns.forEach(colState => {
          let colObj = self.columnObjects.find(o => o.column === colState.column)

          if (colObj) {
            colObj.visible = colState.visible

            if (this.options.hideUniqueKey && this.options.uniqueKey === colObj.column) colObj.visible = false
          }
        })
      }
    },
    updateAutoTotals() {
      if (!this.$refs.table) return
      const refTable = this.$refs.table.$refs.table

      const filteredData = refTable.filteredData
      const allFilteredData = refTable.allFilteredData

      //detect data types and determine columns with totals
      for (const [index, item] of this.columnObjects.entries()) {
        if (
          index > 0 && //skip first column
          this.options.autoTotalColumns &&
          !this.options.autoTotalColumns.includes(item.column)
        )
          continue

        if (index === 0) {
          //total records
          item.total.label = 'Count:'
          item.total.value = filteredData.length
          item.total.overallValue = allFilteredData.length
        } else if (item.type === 'float' || item.type === 'number') {
          item.total.label = 'Sum:'
          item.total.value = filteredData.sum(item.column).toFixed(2)
          item.total.overallValue = allFilteredData.sum(item.column).toFixed(2)
        } else if (item.type === 'int') {
          item.total.label = 'Sum:'
          item.total.value = filteredData.sum(item.column).toFixed(0)
          item.total.overallValue = allFilteredData.sum(item.column).toFixed(0)
        }

        //when first value in the column, for example, Phone, looks like integer, but summarizing will fail
        if (item.total.value === 'NaN') item.total.value = ''
        if (item.total.overallValue === 'NaN') item.total.overallValue = ''
      }
    },

    updateCustomTotals(data, filter = undefined) {
      const self = this
      self.totals = []

      self.columns.forEach(column => {
        //column totals
        if (self.options.totalColumns) {
          let col = self.options.totalColumns.find(c => c.name === column)

          if (self.options.totalColumns && col) {
            let _val = 0

            if (filter) {
              _val = data.filter(t => t[filter.name].toLowerCase().startsWith(filter.value.toLowerCase())).sum(column)
            } else {
              _val = data.sum(column)
            }

            let total = {
              alias: col.alias || col.column,
              value: _val,
              style: col.style
            }

            if (col.formula) total.formula = col.formula

            self.totals.push(total)
          }
        }
      })

      self.totals.forEach(t => {
        if (t.formula) {
          t.value = eval('`' + t.formula + '`')
        }
      })

      //add count total
      if (this.options.totalColumns) {
        if (this.options.totalColumns.find(t => t.name === 'Count')) {
          let total = {
            alias: 'Count',
            value: data.length
          }

          this.totals.push(total)
        }
      }
    },
    viewItem(id) {
      this.$emit('view-item', id)
    },
    editItem(id) {
      this.$emit('edit-item', id)
    },
    deleteItem(id) {
      this.$emit('delete-item', id)
    },

    applyCustomSorting() {
      const self = this

      this.ct_options.customSorting = {}

      //detect data types and apply corresponding sorting
      for (let item of this.columnObjects) {
        //disable client side sorting in server mode

        if (this.mode === 'server') {
          self.ct_options.customSorting[item.column] = function () {
            return
          }

          continue
        }

        if (item.column === this.ct_options.uniqueKey && ['int'].includes(item.type)) {
          if (!self.ct_options.customSorting[item.column])
            self.ct_options.customSorting[item.column] = function (ascending) {
              return function (a, b) {
                let v1 = a[item.column]
                let v2 = b[item.column]

                //Allows to keep inserted row with Empty ID at the top of table
                if (item.column === self.ct_options.uniqueKey) {
                  if (v1 === '') v1 = Number.MAX_VALUE.toString()
                  if (v2 === '') v2 = Number.MAX_VALUE.toString()
                }

                return self.$helpers.compare.float(v1, v2, ascending)
              }
            }
        }

        if (item.column !== this.ct_options.uniqueKey && ['int', 'float', 'number'].includes(item.type)) {
          if (!self.ct_options.customSorting[item.column])
            self.ct_options.customSorting[item.column] = function (ascending) {
              return function (a, b) {
                return self.$helpers.compare.float(a[item.column], b[item.column], ascending)
              }
            }
        }

        if (item.type === 'date') {
          if (!self.ct_options.customSorting[item.column])
            self.ct_options.customSorting[item.column] = function (ascending) {
              return function (a, b) {
                return self.$helpers.compare.date(a[item.column], b[item.column], ascending)
              }
            }
        }

        if (item.type === 'month') {
          if (!self.ct_options.customSorting[item.column])
            self.ct_options.customSorting[item.column] = function (ascending) {
              return function (a, b) {
                return self.$helpers.compare.month(a[item.column], b[item.column], ascending)
              }
            }
        }

        if (item.type === 'weekday') {
          if (!self.ct_options.customSorting[item.column])
            self.ct_options.customSorting[item.column] = function (ascending) {
              return function (a, b) {
                return self.$helpers.compare.weekday(a[item.column], b[item.column], ascending)
              }
            }
        }
      }
    },
    cancelInsert() {
      if (this.isInserting && this.dataSet.length > 0) {
        // Remove the first row if it's in inserting state
        const firstRow = this.dataSet[0]
        if (firstRow.isInserting) {
          // Remove the row from the dataset
          this.dataSet.splice(0, 1)

          // Clear editing state for the removed row
          if (this.$refs.table && this.$refs.table.$children[0]) {
            this.$refs.table.$children[0].editing = this.$refs.table.$children[0].editing.filter(
              i => i.rowUID !== firstRow.uid
            )
          }

          // Reset inserting state
          this.isInserting = false

          // Refresh the table view
          this.refresh()
        }
      }
    },
    isRowEditing(row) {
      return row.isRowEditing
    },
    setRowEditing(row, isEditing) {

      console.log('setRowEditing:')
      console.log('this.dataSet:', this.dataSet)
      console.log('row:', row)
      console.log('isEditing:', isEditing)
      let rowIndex = this.dataSet.findIndex(i => i[this.ct_options.uniqueKey] === row[this.ct_options.uniqueKey])

      console.log('rowIndex:', rowIndex)
      row.isRowEditing = isEditing

      this.preventComponentUpdate = true

      console.log('setRowEditing.END:')
      Vue.set(this.dataSet, rowIndex, row)
    },
    saveEditedRecord(row) {
      row.isLoading = true

      let rowIndex = this.dataSet.findIndex(i => i[this.ct_options.uniqueKey] === row[this.ct_options.uniqueKey])

      this.preventComponentUpdate = true
      Vue.set(this.dataSet, rowIndex, row)

      this.options.saveEditedRecordCallback(row).then(response => {
        row.isLoading = false

        this.preventComponentUpdate = true
        Vue.set(this.dataSet, rowIndex, row)

        if (!response) return
        //hide editable cells
        //this.$refs.table.$children[0].editing = [];
        // this.$refs.table.$children[0].editing = this.$refs.table.$children[0].editing.filter(i => i.rowUID !== row.uid)

        row.isRowEditing = false
        Vue.set(this.dataSet, rowIndex, row)
      })
    },
    async revertEditedRecord(row) {

      //this.$refs.table.$children[0].editing = this.$refs.table.$children[0].editing.filter(i => i.rowUID !== row.uid)
      console.log('revertEditedRecord')
      console.log('row:', row)
      console.log('this.dataSet:', this.dataSet)
      let rowIndex = this.dataSet.findIndex(i => i[this.ct_options.uniqueKey] === row[this.ct_options.uniqueKey])

      row.isRowEditing = false

      await this.options.revertEditedRecordCallback()

    },
    insertNewRow(payload) {
      payload.uid = this.$helpers.uuidv4()

      this.isInserting = true

      payload.isInserting = true

      this.dataSet.unshift(payload)

      Vue.set(this.dataSet, 0, payload)

      this.refresh()

      this.$nextTick(() => {
        if (!this.$refs.table) return

        for (let prop in payload) {
          this.$refs.table.$children[0].editing.push({
            id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
            column: prop,
            originalValue: payload[prop],
            rowUID: payload.uid
          })
        }
        //sort by ID to keep new line at the top
        this.$refs.table.setOrder(this.ct_options.uniqueKey, true)
      })

      return this.dataSet
    },
    setOrder(columnName, ascending) {
      if (this.$refs.table) this.$refs.table.setOrder(columnName, ascending)
    },
    saveNewRecord(row) {
      row.isLoading = true

      let rowIndex = this.dataSet.findIndex(i => i.uid === row.uid)

      this.preventComponentUpdate = true
      Vue.set(this.dataSet, rowIndex, row)

      this.options.saveNewRecordCallback(row).then(response => {
        row.isLoading = false

        this.preventComponentUpdate = true
        Vue.set(this.dataSet, rowIndex, row)

        if (!response) return
        //hide editable cells
        //this.$refs.table.$children[0].editing = [];
        this.$refs.table.$children[0].editing = this.$refs.table.$children[0].editing.filter(i => i.rowUID !== row.uid)

        row.isInserting = false
        Vue.set(this.dataSet, rowIndex, row)
      })
    },

    async revertNewRecord(row) {
      this.options.revertNewRecordCallback(row).then(() => {
        this.dataSet.splice(0, 1)

        this.$refs.table.$children[0].editing = this.$refs.table.$children[0].editing.filter(i => i.rowUID !== row.uid)
        ///this.toggleDescription(this.$constants.CUSTOM_TABLE.NEW_ROW_ID);

        row.isInserting = false

        //this.$forceUpdate();
      })

      ///this.$emit("revert-new-record");
    },
    isNewRecord(row) {
      if (!row) return false
      return row[this.ct_options.uniqueKey] === this.$constants.CUSTOM_TABLE.NEW_ROW_ID
    },
    onColumnUpdate(id, name, value) {
      if (typeof this.deferredUpdateTimerId !== 'undefined') {
        clearTimeout(this.deferredUpdateTimerId)
      }
      this.deferredUpdateTimerId = setTimeout(() => {
        this.$emit('column-update', { id: id, name: name, value: value })
      }, 1000)
    },
    validateCell() {
      return true
    },
    findColumnType(columnName) {
      let col = this.columnObjects.find(c => c.column === columnName)

      if (col) return col.type

      return false
    },
    isStringColumn(columnName) {
      let col = this.columnObjects.find(c => c.column === columnName)

      if (col && col.type === 'string') return true

      return false
    },
    isNumericColumn(columnName) {
      let col = this.columnObjects.find(c => c.column === columnName)

      if (col && ['int', 'float', 'number'].includes(col.type)) return true

      return false
    },

    isDateColumn(columnName) {
      let col = this.columnObjects.find(c => c.column === columnName)

      if (col && col.type === 'date') return true

      return false
    },
    isTimeColumn(columnName) {
      let col = this.columnObjects.find(c => c.column === columnName)

      if (col && col.type === 'time') return true

      return false
    },
    dropdownOptionsToString(value) {
      let result = ''

      console.log('dropdownOptionsToString.value', value)

      if (Array.isArray(value)) {
        result = value.map(i => i.label).join(',')
      }

      return result
    },
    isDropdownColumn(columnName) {
      if (!this.options.dropdownColumns) return false
      let col = this.options.dropdownColumns.find(c => c.name === columnName)

      if (col) return true

      return false
    },
    isMaskedColumn(columnName) {
      if (!this.options.maskedColumns) return false
      let col = this.options.maskedColumns.find(c => c.name === columnName)

      if (col) return col

      return false
    },
    getDropdownValue(row, columnName) {
      let value = { id: row[`${columnName}_ID`], label: row[columnName] }

      return value
    },
    getDropdownColumn(columnName) {
      return this.options.dropdownColumns.find(c => c.name === columnName)
    },
    getDropdownOptions(columnName) {
      let col = this.options.dropdownColumns.find(c => c.name === columnName)

      if (col) return col.options

      return []
    },
    getDropdownTemplates(columnName) {
      const column = this.getDropdownColumn(columnName)
      return column ? column.templates : null
    },
    onChangeDropdownColumn(columnName, row, id, value) {
      let idx = undefined

      if (row.uid) idx = this.dataSet.findIndex(item => item.uid === row.uid)
      else idx = this.dataSet.findIndex(item => item.ID === row.ID)

      row[`${columnName}_ID`] = value.id
      row[columnName] = value.label

      this.preventComponentUpdate = true //to avoid drawTable call in watch.data
      Vue.set(this.dataSet, idx, row)

      this.$emit('inline-dropdown-change', {
        id: id,
        column: columnName,
        value: value,
        row: row
      })
    },
    onTableDropdownOpen() {
      //this.isTableDropdownOpened = true;
    },
    onTableDropdownClose() {
      //this.isTableDropdownOpened = false;
    }
  },
  watch: {
    data(newVal) {
      //wait for $refs
      setTimeout(() => {
        this.saveState()
      }, 250)

      if (!this.preventComponentUpdate) {
        //provoke vue-tables-2 component update
        this.componentKey = ''

        this.drawTable(newVal)
      }
      this.preventComponentUpdate = false
    },
    settingsState(newVal, oldVal) {
      //20201911 update colums visibility when settings loaded
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.updateColumnsVisibility()
      }
    }
  }
}
</script>

<style scoped>
.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}

::v-deep .VueTables.VueTables--client {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

::v-deep .table-responsive {
  flex: 1;
  min-width: 0;
  overflow-x: auto  !important;
  overflow-y: visible  !important;/*110225 inline dropdown overflow*/
  max-width: 100%;
}

::v-deep .table-responsive table {
    width: 100% !important;
    max-width: none !important;
  }

/* Ensure table fits content but doesn't exceed container */
::v-deep .VueTables__table {
  min-width: min-content;
  max-width: 100%;
  width: 100%;
}


/* Base Table Styles */
::v-deep .VueTables__table tr {
  cursor: pointer;
}

::v-deep table {
  width: 100%;

}

::v-deep table tbody {
  overflow-x: auto !important;
}

::v-deep .VueTables__table th {
  font-weight: 500;
  text-align: left;
  padding: 8px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

::v-deep td {
  position: relative;
  /* padding: 8px 8px !important; 280225 to decrease over space */
}

/* Table Components */
::v-deep .VueTables__limit>div>.row {
  position: absolute;
  left: 1em;
  margin: -1em;
}

::v-deep .VuePagination__count {
  display: none;
}

::v-deep .VueTables__filters-row input::placeholder {
  color: #e4e7ea;
}

/* Child Row Togglers */
::v-deep .VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

::v-deep .VueTables__child-row-toggler--closed::before {
  font-family: 'FontAwesome';
  content: '\f0fe';
}

::v-deep .VueTables__child-row-toggler--open::before {
  font-family: 'FontAwesome';
  content: '\f146';
}

::v-deep .multiselect__tags {
 
  padding: 6px 30px 0 6px !important;
}
/* Editable Cell Styles */
.editable-cell {
  min-height: 2.8em;
  width: 100%;
  border: 1px dashed lightgrey;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px;

  white-space: normal;
  word-wrap: break-word;
  line-height: normal;
}

.editing-cell-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-width: 0;
}

.inline-buttons-container {
  position: absolute;
  top: -35px;
  left: 0;
  display: flex;
  gap: 4px;
  background: white;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Multiselect Customization */
::v-deep .multiselect__input {
  padding: 4px !important;
  margin-bottom: 0 !important;
  line-height: 16px !important;
  min-height: 0 !important;
}

::v-deep .multiselect__single {
  white-space: normal !important;
  word-wrap: break-word;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 5px !important;
}

/* Form Element Constraints */
.editing-cell-container :deep(input),
.editing-cell-container :deep(.form-control),
.editing-cell-container :deep(.multiselect),
.editing-cell-container :deep(.vue-daterange-picker),
.editing-cell-container :deep(.inline-time-picker),
.editing-cell-container :deep(.ct-inline-select) {
  min-width: 0 !important;
  max-width: 100% !important;
  flex: 1 1 auto !important;
}

/* Width Classes 
@media screen and (max-width: 1200px) {
  .w-short {
    min-width: 12em !important;
    width: 12em !important;
  }

  .w-middle {
    min-width: 16em !important;
    width: 16em !important;
  }

  .w-long {
    width: 20em !important;
  }
}

*/

/* Utility Classes */
::v-deep .inactive {
  text-decoration: 'line-through';
}

::v-deep .highlight {
  background-color: #ffffed;
}

::v-deep #full-data-link {
  padding: 5px;
  font-size: 1.5em;
  cursor: pointer;
}



/* Media Queries */
@media screen and (max-width: 1200px) {
  #card ::v-deep table {
    border: 0;
  }

  #card ::v-deep table thead {
    display: none;
  }

  #card .auto-totals {
    display: none;
  }

  #card ::v-deep table tr {
    margin-bottom: 20px;
    display: block;
    border-bottom: 2px solid #ddd;
    box-shadow: 2px 2px 1px #dadada;
  }

  ::v-deep #card div .table-responsive table td {
    display: block;
    padding-left: 10em;
    text-align: left;
    font-size: 13px;
  }

  ::v-deep #card .calendar-table table td {
    display: table-cell !important;
    padding-left: 0em !important;
    text-align: center !important;
  }

  ::v-deep #card .calendar-table table tr {
    margin-bottom: 0px !important;
    display: table-row !important;
    border-bottom: 0 !important;
    box-shadow: none !important;
  }

  #card ::v-deep table td:last-child {
    border-bottom: 0;
  }

  #card ::v-deep table td::before {
    content: attr(data-label);
    padding-top: 1.3em;
    margin-left: -9em;
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }

  #card ::v-deep tbody {
    line-height: 0 !important;
  }

  ::v-deep .table-responsive {
    overflow: auto !important;
  }

  ::v-deep table {
    overflow: auto !important;
  }
}

@media only screen and (max-width: 900px) {
  #pivot ::v-deep table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;
  }

  #pivot ::v-deep thead {
    display: block;
    float: left;
  }

  #pivot ::v-deep tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  #pivot ::v-deep thead tr {
    display: block;
  }

  #pivot ::v-deep tbody tr {
    display: inline-block;
    vertical-align: top;
  }

  #pivot ::v-deep th {
    display: block;
    text-align: right;
  }

  #pivot ::v-deep td {
    display: block;
    text-align: left;
  }

  #pivot ::v-deep th,
  #pivot ::v-deep td {
    margin: 0;
    height: 4em;
  }

  #pivot ::v-deep .table thead th {
    border-bottom: 0;
  }

  #pivot ::v-deep .table-bordered th,
  .table-bordered td {
    border: 1px solid #c8ced3;
  }

  #pivot ::v-deep .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }

  #pivot ::v-deep * html .cf {
    zoom: 1;
  }

  #pivot ::v-deep *:first-child+html .cf {
    zoom: 1;
  }
}
</style>