<!--
USED IN:
- TaskSubmission
- SampleRequestSubmission
- Barrel submission
- Payroll report

WORKS JUST IN SINGLE MODE FOR NOW!
This version uses date range picker custom which allows manual dates input
-->

<template>
  <div class="form-group">
    <!--
    this.valueSingle: {{this.valueSingle}}
    valueSingleAsString: {{valueSingleAsString}}
    this.dateRangePicker.value: {{this.dateRangePicker.value}}
    -->

    <div>
      <label class="col-form-label" :for="id" v-if="!hideLabel">{{
        label
      }}</label>
    </div>

    <input
      :id="id"
      v-if="readonly || !isEditMode"
      :readonly="readonly ? readonly : !isEditMode"
      type="text"
      :class="['form-control', readonly ? 'readonly' : '']"
      :value="valueSingleAsString"
      @dblclick="dblclickField($event)"
      @blur="onInputBlur($event)"
    />

    <date-range-picker-custom
      :id="id"
      :ref="id"
      v-if="isEditMode && !readonly"
      v-model="dateRangePicker.value"
      :ranges="{}"
      :single-date-picker="dateRangePicker.options.singleDatePicker"
      :show-time="dateRangePicker.options.showTime"
      @change="updateDateRangePicker"
    />
    <!--@change="updateDateRangePicker" @input="updateDateRangePicker" -->

    <div v-if="isInvalid && isEditMode" class="invalid-feedback d-block">
      {{ label }} is required
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import DateRangePickerCustom from '@/components/DateRangePickerCustom'

import { FORMCONTROLMODE } from '@/shared/constants'

export default {
  components: {
    DateRangePickerCustom
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    showTime: {
      type: Boolean,
      default: false
    },

    valueSingle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    mode: {
      type: Number,
      default: FORMCONTROLMODE.VIEW
    },
    required: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  
  },
  data: function () {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isEditMode: false,
      isChanged: false,
      isInvalid: false,
      dateRangePicker: {
        options: {
          opens: 'left',
          singleDatePicker: true,
          showTime: this.showTime,
          //timePicker: true,
          //timePicker24Hour: true,
          autoApply: true,
          linkedCalendars: false,
          ranges: false,
          locale: {
            direction: 'ltr', //direction of text
            // format: "YYYY-MM-DD", //format of the dates displayed
            format: this.format,
            separator: ' - ' //separator between the two ranges
          }
        },
        value: {
          startDate: null,
          endDate: null
        }
      }
    }
  },
  computed: {
    format () {
      return this.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    },

    valueSingleAsString () {
      //console.log('valueSingleAsString',this.dateRangePicker.value)

      if (
        typeof this.dateRangePicker.value === 'object' &&
        this.dateRangePicker.value !== null
      ) {
        if (this.dateRangePicker.value.startDate === '') return ''

        return moment(this.dateRangePicker.value.startDate).format(this.format)
      }

      if (typeof this.dateRangePicker.value === 'string') {
        if (this.dateRangePicker.value === '') return ''

        return moment(this.dateRangePicker.value).format(this.format)
      }

      return ''
    }
  },
  created: function () {
    //console.log('InlineDatePicker.created',this.valueSingle)

    this.dateRangePicker.value = {
      startDate: this.valueSingle,
      endDate: this.valueSingle
    }
  },
  mounted: function () {
    this.isEditMode =
      (this.mode === FORMCONTROLMODE.EDIT ||
        this.mode === FORMCONTROLMODE.CREATE) &&
      !this.readonly
  },

  methods: {
    test () {
      //console.log('inlinedatepicker.test', JSON.stringify(this.dateRangePicker.value))

      if (typeof this.dateRangePicker.value === 'object') {
        this.isInvalid =
          this.required &&
          !this.dateRangePicker.value.startDate &&
          !this.dateRangePicker.value.endDate
      }

      if (typeof this.dateRangePicker.value === 'string') {
        this.isInvalid = this.required && !this.dateRangePicker.value
      }

      return !this.isInvalid
    },
    onInputBlur () {},
    updateDateRangePicker (e) {
      //console.log('InlineDatePicker.updateDateRangePicker', e)

      //todo - wrong condition if e will be Object
      if (this.valueSingle !== e) {
        this.isChanged = true
      } else {
        this.isChanged = false
      }

      this.dateRangePicker.value = {
        startDate: e,
        endDate: e
      }

      if (!this.test()) return

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = false
      }

      let id = this.id.replace(/datepicker:/g, '')

      if (this.isChanged) {
        //allow to get all parameters as second parameter in the parent via $event property
        this.$emit('changed', {
          id: id,
          value: this.dateRangePicker.value,
          mode: this.mode,
          valueSingleAsString: this.valueSingleAsString
        })

        this.$emit('input', this.valueSingleAsString)
      }
    },
    dblclickField () {
      //let s = this.dateRangePicker.value;
      if (this.isInvalid) return
      if (this.readonly) return

      //let id = e.currentTarget.id.replace(/datepicker:/g, "");

      if (this.mode === FORMCONTROLMODE.VIEW) {
        this.isEditMode = !this.isEditMode
      }
    }
  },
  watch: {
    valueSingle (newVal, oldVal) {
      //20200122 to avoid invalid dates
      if (newVal === null) newVal = ''

      if (newVal !== oldVal) {
        this.dateRangePicker.value = {
          startDate: newVal,
          endDate: newVal
        }

        //update value but don't trigger change event
        if (this.$refs[this.id])
          this.$refs[this.id].setValue(this.dateRangePicker.value)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .picker-single {
  width: 100%;
}
::v-deep .vue-daterange-picker {
  width: 100%;
}

::v-deep .report-range-text {
  width: 100% !important;
}

::v-deep .edit,
.edit:focus {
  background-color: #ffffed;
}

::v-deep .readonly {
  background-color: #e8e8e873 !important;
  cursor: default !important;
}

::v-deep .form-control[readonly] {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}
</style>
