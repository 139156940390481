var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-daterange-picker"
  }, [_c('date-range-picker', {
    class: [_vm.singleDatePicker ? 'picker-single' : 'picker-multiple'],
    attrs: {
      "time-picker": _vm.showTime,
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate,
      "opens": _vm.dateRange.options.opens,
      "locale-data": _vm.dateRange.options.locale,
      "single-date-picker": _vm.singleDatePicker,
      "auto-apply": _vm.dateRange.options.autoApply,
      "show-dropdowns": true,
      "ranges": _vm.ranges,
      "linked-calendars": _vm.dateRange.options.linkedCalendars
    },
    on: {
      "update": _vm.update,
      "toggle": _vm.onToggle,
      "select": _vm.onSelect
    },
    model: {
      value: _vm.selected.period,
      callback: function callback($$v) {
        _vm.$set(_vm.selected, "period", $$v);
      },
      expression: "selected.period"
    }
  }, [!_vm.singleDatePicker ? _c('masked-input', {
    ref: "picker-input",
    staticClass: "form-control picker-input-multiple",
    attrs: {
      "slot": "input",
      "mask": _vm.mask,
      "placeholder": "yyyy-mm-dd - yyyy-mm-dd"
    },
    on: {
      "blur": _vm.onInputBlur,
      "key-up": _vm.onKeyUp
    },
    slot: "input",
    model: {
      value: _vm.dateRange.input.value,
      callback: function callback($$v) {
        _vm.$set(_vm.dateRange.input, "value", $$v);
      },
      expression: "dateRange.input.value"
    }
  }) : _vm._e(), _vm.singleDatePicker ? _c('masked-input', {
    ref: "picker-input",
    staticClass: "form-control picker-input-single",
    attrs: {
      "slot": "input",
      "mask": _vm.mask,
      "placeholder": _vm.format
    },
    on: {
      "blur": _vm.onInputBlur,
      "key-up": _vm.onKeyUp
    },
    slot: "input",
    model: {
      value: _vm.dateRange.input.value,
      callback: function callback($$v) {
        _vm.$set(_vm.dateRange.input, "value", $$v);
      },
      expression: "dateRange.input.value"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }