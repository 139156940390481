import {ifAuthenticated} from './helpers'
import {MODULES} from '@/shared/constants'

const StoreLocator = () => import('@/views/SalesAdministrative/StoreLocator')
const Invoices = () => import('@/views/SalesAdministrative/Invoices')
const InvoiceSubmissionForm = () => import('@/views/SalesAdministrative/InvoiceSubmissionForm/index.js')
const Products = () => import('@/views/SalesAdministrative/Products/Products')
const Products2 = () => import('@/views/SalesAdministrative/Products/Products2')


const ProductAliases = () => import('@/views/SalesAdministrative/Products/ProductAliases')
const ProductSubmissionForm = () => import('@/views/SalesAdministrative/Products/ProductSubmissionForm')
const DistributorsMarkets = () => import('@/views/SalesAdministrative/DistributorsMarkets')
const AccountAliases = () => import('@/views/RelationshipManagement/AccountAliases')

const DataImport = {template: '<div></div>'}

export default {
  path: '',
  name: 'Sales administrative',
  meta: {icon: 'icon-star'},
  component: {
    render (c) {
      return c('router-view')
    }
  },
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/sales-administrative/account-locator',
      name: 'Account locator',
      meta: {icon: 'icon-chart'},
      component: StoreLocator,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/sales-administrative/sales-data-import',
      name: 'Sales data import',
      meta: {icon: 'icon-list'},
      component: DataImport,
      beforeEnter (to, from, next) {
        ifAuthenticated(to, from, next)

        window.open('https://crm.koval-distillery.com/data-import/public/', '_blank')
      }
    },
    {
      path: '/sales-administrative/invoices',
      name: 'Invoices',
      component: Invoices,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Invoices, icon: 'icon-list'}
    },
    {
      path: '/sales-administrative/invoice/:action/:id?',
      name: 'Invoice submission',
      component: InvoiceSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Invoices, hideFromMenu: true}
    },

    {
      path: '/sales-administrative/products-old',
      name: 'Products Old',
      component: Products,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Products, icon: 'icon-list'}
    },
    {
      path: '/sales-administrative/products',
      name: 'Products',
      component: Products,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Products, icon: 'icon-list'}
    },
    {
      path: '/sales-administrative/products2',
      name: 'Products2',
      component: Products2,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Products, icon: 'icon-list'}
    },

    {
      path: '/sales-administrative/account-aliases',
      name: 'Account aliases',
      component: AccountAliases,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Accounts, icon: 'icon-list'}
    },
    {
      path: '/sales-administrative/product-aliases',
      name: 'Product aliases',
      component: ProductAliases,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Products, icon: 'icon-list'}
    },
    {
      path: '/sales-administrative/product/:action/:id?',
      name: 'Product submission',
      component: ProductSubmissionForm,
      props: true,
      beforeEnter: ifAuthenticated,
      meta: {module: MODULES.Products, hideFromMenu: true}
    },
    {
      path: '/sales-administrative/distributors-markets',
      name: 'Distributors / Markets',
      meta: {icon: 'icon-list'},
      component: DistributorsMarkets,
      beforeEnter: ifAuthenticated
    }
  ]
}
