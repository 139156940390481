<template>
  <div>
    <b-overlay
      :show="isLoading"
      :opacity="0.5"
      spinner-variant="secondary"
      rounded="sm"
    >
      <div v-if="compact">
        <b-container fluid style="width: 200">
          <b-row class="pl-3 pr-3" align-v="center">
            <b-col lg="10" :class="showLabels ? '' : 'p-1'">
              <b-row>
                <b-col
                  lg=""
                  class=""
                  v-for="(filter, index) in regularInputs"
                  :key="`rinput-${index}`"
                >
                  <div class="mb-1 mr-1" :id="`tt-${filter.name}`">
                    <label
                      class="col-form-label pb-1 pt-0"
                      v-if="showLabels"
                      :for="filter.name"
                      >{{ filter.title }}</label
                    >

                    <b-input
                      :id="`ip-${datasetName}-${index}-${filter.name}`"
                      :ref="filter.name"
                      v-model="selected[filter.name]"
                      @input="onInput"
                    />
                  </div>
                  <b-tooltip
                    v-if="filter.tooltip"
                    :target="`tt-${filter.name}`"
                    placement="top"
                    triggers="focus"
                    delay="5000"
                  >
                    {{ filter.tooltip }}
                  </b-tooltip>
                </b-col>

                <b-col
                  lg=""
                  class=""
                  v-for="(filter, index) in regularDropdowns"
                  :key="`rin-${index}`"
                >
                  <div class="mb-1 mr-1" :id="`tt-${filter.name}`">
                    <label
                      class="col-form-label pb-1 pt-0"
                      v-if="showLabels"
                      :for="filter.name"
                      >{{ filter.title }}</label
                    >

                    <multiselect
                      v-if="filter.group_values"
                      :id="`ms-${datasetName}-${index}-${filter.name}`"
                      :ref="filter.name"
                      v-model="selected[filter.name]"
                      @input="onSelectInput"
                      @close="onSelectClose"
                      :allow-empty="true"
                      :group-values="filter.group_values"
                      :group-label="filter.group_label"
                      :group-select="true"
                      :track-by="filter.trackby"
                      :label="filter.label"
                      :options="filter.options"
                      :multiple="filter.multiple"
                      :show-labels="false"
                      :placeholder="`select ${filter.title.toLowerCase()}`"
                    />
                    <multiselect
                      v-if="!filter.group_values"
                      :id="`ms-${datasetName}-${index}-${filter.name}`"
                      :ref="filter.name"
                      v-model="selected[filter.name]"
                      @input="onSelectInput"
                      @close="onSelectClose"
                      :allow-empty="true"
                      :track-by="filter.trackby"
                      :label="filter.label"
                      :options="filter.options"
                      :multiple="filter.multiple"
                      :show-labels="false"
                      :placeholder="`select ${filter.title.toLowerCase()}`"
                    />
                  </div>
                  <b-tooltip
                    v-if="filter.tooltip"
                    :target="`tt-${filter.name}`"
                    placement="top"
                    triggers="focus"
                    delay="5000"
                  >
                    {{ filter.tooltip }}
                  </b-tooltip>
                </b-col>
                <b-col
                  v-for="(dateRange, index) in dateRanges"
                  :key="`dr-${index}`"
                >
                  <div :id="`tt-${dateRange.name}`">
                    <label class="col-form-label pb-1 pt-0" v-if="showLabels">{{
                      dateRange.title
                    }}</label>
                    <date-range-picker-custom
                      :ref="`${dateRange.name}`"
                      :id="dateRange.name"
                      :default-range="dateRange.defaultRange"
                      v-model="selected[dateRange.name]"
                      :allow-empty="dateRange.allowEmpty"
                      @mounted="onDateRangeMounted($event, dateRange.name)"
                      @input="onDateRangeInput"
                      @change="onDateRangeChange(dateRange.name)"
                    />
                  </div>
                  <b-tooltip
                    v-if="dateRange.tooltip"
                    :target="`tt-${dateRange.name}`"
                    placement="top"
                    triggers="focus"
                    delay="5000"
                  >
                    {{ dateRange.tooltip }}
                  </b-tooltip>
                </b-col>

                <b-col lg="3" v-if="dimensions">
                  <div :id="`tt-${dimensions.name}`">
                    <label
                      class="col-form-label pb-1 pt-0"
                      v-if="showLabels"
                      :for="dimensions.name"
                      >{{ dimensions.title }}</label
                    >
                    <multiselect
                      :id="dimensions.name"
                      :ref="dimensions.name"
                      v-model="selected[dimensions.name]"
                      @input="onSelectInput"
                      @close="onSelectClose"
                      :track-by="dimensions.trackby"
                      :label="dimensions.label"
                      :options="dimensions.options"
                      :multiple="dimensions.multiple"
                      :show-labels="false"
                      :placeholder="dimensions.placeholder"
                    />
                  </div>
                  <b-tooltip
                    v-if="dimensions.tooltip"
                    :target="`tt-${dimensions.name}`"
                    placement="top"
                    triggers="focus"
                    delay="5000"
                  >
                    {{ dimensions.tooltip }}
                  </b-tooltip>
                </b-col>
                <b-col lg="3" v-if="measures">
                  <div :id="`tt-${measures.name}`">
                    <label
                      class="col-form-label pb-1 pt-0"
                      v-if="showLabels"
                      :for="measures.name"
                      >{{ measures.title }}</label
                    >
                    <multiselect
                      :id="measures.name"
                      :ref="measures.name"
                      v-model="selected[measures.name]"
                      @input="onSelectInput"
                      @close="onSelectClose"
                      :track-by="measures.trackby"
                      :label="measures.label"
                      :options="measures.options"
                      :multiple="measures.multiple"
                      :show-labels="false"
                      :placeholder="measures.placeholder"
                    />
                  </div>
                  <b-tooltip
                    v-if="measures.tooltip"
                    :target="`tt-${measures.name}`"
                    placement="top"
                    triggers="focus"
                    delay="5000"
                  >
                    {{ measures.tooltip }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" lg="2" v-if="!hideSearchButton">
              <b-container fluid>
                <b-row align-h="center" class="text-center">
                  <b-col cols="6" lg="12" class="p-2">
                    <b-button
                      @click="searchData"
                      style="width: 8em"
                      :variant="
                        isUpdateRequired ? 'success' : 'outline-secondary'
                      "
                    >
                      <font-awesome-icon icon="sync" />
                    </b-button>
                  </b-col>
                  <b-col cols="6" lg="12" v-if="!hideResetButton" class="p-2">
                    <b-button
                      @click="onResetClick"
                      style="width: 8em"
                      variant="outline-secondary"
                    >
                      <font-awesome-icon icon="backspace" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-card v-if="!compact" no-body header-bg-variant="white">
        <template #header>
          <font-awesome-icon
            v-b-toggle.collapse-fp
            v-if="panelState.isUnfolded"
            :style="iconStyleLeft"
            size="2x"
            icon="chevron-up"
          />
          <font-awesome-icon
            v-b-toggle.collapse-fp
            v-if="!panelState.isUnfolded"
            :style="iconStyleLeft"
            size="2x"
            icon="chevron-down"
          />

          <font-awesome-icon
            id="tooltip-cf"
            v-show="!hideCustomFilters && panelState.isDropdownsBar"
            :style="iconStyleRight"
            size="2x"
            icon="th"
            @click="toggleView"
          />
          <font-awesome-icon
            id="tooltip-dd"
            v-show="!hideCustomFilters && panelState.isCustomFiltersBar"
            :style="iconStyleRight"
            size="2x"
            icon="caret-square-down"
            @click="toggleView"
          />

          <b-tooltip
            target="tooltip-cf"
            triggers="hover"
            placement="left"
            delay="2000"
          >
            Switch to custom filters
          </b-tooltip>
          <b-tooltip
            target="tooltip-dd"
            triggers="hover"
            placement="left"
            delay="2000"
          >
            Switch to simple filters
          </b-tooltip>
        </template>

        <b-collapse
          id="collapse-fp"
          class="mt-0"
          v-model="panelState.isUnfolded"
        >
          <custom-filters-bar
            ref="cfb"
            v-show="panelState.isCustomFiltersBar && !expressionBuilder.visible"
            :dataset-name="datasetName"
            @edit="onCustomFilterEdit"
            @create="onCustomFilterCreate"
            @loaded="onCustomFiltersLoaded"
            @filter-selected="onCustomFilterSelected"
            @state-change="onCustomFiltersCollapseStateChage"
          />

          <expression-builder
            ref="exb"
            v-show="expressionBuilder.visible"
            :districts="districts"
            :fields="filters"
            :dimensions="dimensions"
            :measures="measures"
            :dataset-name="datasetName"
            @close="onExpressionBuilderClose"
            @save="onExpressionBuilderSave"
            @async-search-exb="onSearchChangeExb"
          />

          <b-card-body v-show="panelState.isDropdownsBar">
            <b-container fluid>
              <b-row class="pl-3 pr-3" align-v="center">
                <b-col lg="10" :class="showLabels ? '' : 'p-1'">
                  <b-row>
                    <b-col
                      xl="3"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      class=""
                      v-for="(filter, index) in regularInputs"
                      :key="`rinput-${index}`"
                    >
                      <div class="mb-1 mr-1" :id="`tt-${filter.name}`">
                        <label
                          class="col-form-label pb-1 pt-0"
                          v-if="showLabels"
                          :for="filter.name"
                          >{{ filter.title }}</label
                        >

                        <b-input
                          :id="`ip-${datasetName}-${index}-${filter.name}`"
                          :ref="filter.name"
                          v-model="selected[filter.name]"
                          @input="onInput"
                        />
                      </div>
                      <b-tooltip
                        v-show="filter.tooltip"
                        :target="`tt-${filter.name}`"
                        placement="top"
                        triggers="focus"
                        delay="5000"
                      >
                        {{ filter.tooltip }}
                      </b-tooltip>
                    </b-col>
                    <b-col
                      class=""
                      xl="3"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      v-for="(filter, index) in regularDropdowns"
                      :key="`rin-${index}`"
                    >
                      <div class="mb-1 mr-1" :id="`tt-${filter.name}`">
                        <label
                          class="col-form-label pb-1 pt-0"
                          v-if="showLabels"
                          :for="filter.name"
                          >{{ filter.title }}</label
                        >

                        <!-- ASYNC DROPDOWNS -->
                        <span v-if="filter.async">
                          <multiselect
                            :id="filter.name"
                            :ref="filter.name"
                            v-model="selected[filter.name]"
                            :loading="filter.loading"
                            @search-change="
                              $helpers.debounce(onSearchChange, 500)(
                                $event,
                                filter
                              )
                            "
                            @input="onSelectInput"
                            @close="onSelectClose"
                            :track-by="filter.trackby"
                            :label="filter.label"
                            :options="filter.options"
                            :multiple="filter.multiple"
                            :show-labels="false"
                            :placeholder="
                              `type to find ${filter.title.toLowerCase()}`
                            "
                          >
                            <template slot="beforeList">
                              <b-button-group size="sm">
                                <b-button
                                  :pressed.sync="filter.startsWith"
                                  squared
                                  size="sm"
                                  :variant="'ligth'"
                                >
                                  <font-awesome-icon
                                    v-if="!filter.startsWith"
                                    :icon="['far', 'square']"
                                  />
                                  <font-awesome-icon
                                    v-if="filter.startsWith"
                                    :icon="['far', 'check-square']"
                                  />

                                  Starts with...
                                </b-button>
                              </b-button-group>
                            </template>
                          </multiselect>
                        </span>
                        <span v-else>
                          <multiselect
                            v-if="filter.group_values"
                            :id="filter.name"
                            :ref="filter.name"
                            v-model="selected[filter.name]"
                            @input="onSelectInput"
                            @close="onSelectClose"
                            :group-values="filter.group_values"
                            :group-label="filter.group_label"
                            :group-select="true"
                            :track-by="filter.trackby"
                            :label="filter.label"
                            :options="filter.options"
                            :multiple="filter.multiple"
                            :show-labels="false"
                            :placeholder="
                              `select ${filter.title.toLowerCase()}`
                            "
                          />
                          <multiselect
                            v-if="!filter.group_values"
                            :id="filter.name"
                            :ref="filter.name"
                            v-model="selected[filter.name]"
                            @input="onSelectInput"
                            @close="onSelectClose"
                            :track-by="filter.trackby"
                            :label="filter.label"
                            :options="filter.options"
                            :multiple="filter.multiple"
                            :show-labels="false"
                            :placeholder="
                              `select ${filter.title.toLowerCase()}`
                            "
                          />
                        </span>
                      </div>
                      <b-tooltip
                        v-if="filter.tooltip"
                        :target="`tt-${filter.name}`"
                        placement="top"
                        triggers="focus"
                        delay="5000"
                      >
                        {{ filter.tooltip }}
                      </b-tooltip>
                    </b-col>
                    <b-col
                      xl="3"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      v-for="(dateRange, index) in dateRanges"
                      :key="`dr-${index}`"
                    >
                      <div :id="`tt-${dateRange.name}`">
                        <label
                          class="col-form-label pb-1 pt-0"
                          v-if="showLabels"
                          >{{ dateRange.title }}</label
                        >
                        <date-range-picker-custom
                          :ref="dateRange.name"
                          :id="dateRange.name"
                          :default-range="dateRange.defaultRange"
                          v-model="selected[dateRange.name]"
                          :allow-empty="dateRange.allowEmpty"
                          :singleDatePicker="dateRange.singleDatePicker"
                          @mounted="onDateRangeMounted($event, dateRange.name)"
                          @input="onDateRangeInput"
                          @change="onDateRangeChange(dateRange.name)"
                        />
                      </div>
                      <b-tooltip
                        v-if="dateRange.tooltip"
                        :target="`tt-${dateRange.name}`"
                        placement="top"
                        triggers="focus"
                        delay="5000"
                      >
                        {{ dateRange.tooltip }}
                      </b-tooltip>
                    </b-col>

                    <b-col lg="3" v-if="dimensions">
                      <div :id="`tt-${dimensions.name}`">
                        <label
                          class="col-form-label pb-1 pt-0"
                          v-if="showLabels"
                          :for="dimensions.name"
                          >{{ dimensions.title }}</label
                        >
                        <multiselect
                          :id="dimensions.name"
                          :ref="dimensions.name"
                          v-model="selected[dimensions.name]"
                          @input="onSelectInput"
                          @close="onSelectClose"
                          :track-by="dimensions.trackby"
                          :label="dimensions.label"
                          :options="dimensions.options"
                          :multiple="dimensions.multiple"
                          :show-labels="false"
                          :placeholder="dimensions.placeholder"
                        />
                      </div>
                      <b-tooltip
                        v-if="dimensions.tooltip"
                        :target="`tt-${dimensions.name}`"
                        placement="top"
                        triggers="focus"
                        delay="5000"
                      >
                        {{ dimensions.tooltip }}
                      </b-tooltip>
                    </b-col>
                    <b-col lg="3" v-if="measures">
                      <div :id="`tt-${measures.name}`">
                        <label
                          class="col-form-label pb-1 pt-0"
                          v-if="showLabels"
                          :for="measures.name"
                          >{{ measures.title }}</label
                        >
                        <multiselect
                          :id="measures.name"
                          :ref="measures.name"
                          v-model="selected[measures.name]"
                          @input="onSelectInput"
                          @close="onSelectClose"
                          :track-by="measures.trackby"
                          :label="measures.label"
                          :options="measures.options"
                          :multiple="measures.multiple"
                          :show-labels="false"
                          :placeholder="measures.placeholder"
                        />
                      </div>
                      <b-tooltip
                        v-if="measures.tooltip"
                        :target="`tt-${measures.name}`"
                        placement="top"
                        triggers="focus"
                        delay="5000"
                      >
                        {{ measures.tooltip }}
                      </b-tooltip>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="2" v-if="!hideSearchButton">
                  <b-container fluid>
                    <b-row align-h="center" class="text-center">
                      <b-col cols="6" lg="12" class="p-2">
                        <b-button
                          @click="searchData"
                          style="width: 8em"
                          :variant="
                            isUpdateRequired ? 'success' : 'outline-secondary'
                          "
                        >
                          <font-awesome-icon icon="sync" />
                        </b-button>
                      </b-col>
                      <b-col cols="6" lg="12" class="p-2">
                        <b-button
                          @click="resetFilters"
                          style="width: 8em"
                          variant="outline-secondary"
                        >
                          <font-awesome-icon icon="backspace" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-row class="pr-5" align-h="end" v-if="!$isMobile">
        <span style="color: #d3d3d3; font-size: 10px; margin-top: -5px"
          >FP 2.0 [{{ loadingTime }} ms]</span
        >
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import DateRangePickerCustom from '@/components/DateRangePickerCustom'

import ExpressionBuilder from './ExpressionBuilder'
import CustomFiltersBar from './CustomFiltersBar'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Multiselect,
    DateRangePickerCustom,
    ExpressionBuilder,
    CustomFiltersBar
  },
  props: {
    datasetName: {
      type: String,
      default: ''
    },
    districts: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    dimensions: {
      type: Object,
      default: () => {}
    },
    measures: {
      type: Object,
      default: () => {}
    },
    hideCustomFilters: {
      type: Boolean,
      default: false
    },

    hideSearchButton: {
      type: Boolean,
      default: false
    },
    hideResetButton: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ''
    },
    showLabels: {
      type: Boolean,
      default: true
    },
    compact: {
      type: Boolean,
      default: false
    },
    loadDictionaries: {
      type: Function,
      default: null
    },
    loaded: {
      type: Boolean,
      default: undefined
    }
  },
  data: function () {
    return {
      debug: false,
      isLoading: false,
      loadingTimeStart: undefined,
      loadingTimeEnd: undefined,
      panelState: {
        isUnfolded: true,
        isDropdownsBar: false,
        isCustomFiltersBar: true
      },
      expressionBuilder: {
        visible: false,
        value: {}
      },
      showLists: false,
      filtersSaved2Storage: false,
      isFilterChanged: false,
      isPeriodChanged: false,
      isViewChanged: false,
      filterReset: false,
      selected: {},
      formError: '',
      expression: {
        transcription: '',
        json: ''
      }
    }
  },
  computed: {
    ...mapGetters('filteringPanel', ['getPanelState', 'getSelectedFilters']),
    iconStyleRight () {
      return {
        color: 'gray',
        float: 'right',
        cursor: 'pointer',
        margin: '5px'
      }
    },
    iconStyleLeft () {
      return {
        color: 'gray',
        float: 'left',
        cursor: 'pointer',
        margin: '5px'
      }
    },
    regularInputs: function () {
      //show all allowed inputs
      return this.filters.filter(
        f => f.type === 'input' && (f.id ? this.$permitted(f.id).visible : true)
      )
    },
    regularDropdowns: function () {
      //show all selects excluding dimensions, measures and inputs forbidden by permissions
      //20201021
      //return this.filters.filter(f => f.type === 'select' && !['dimensions', 'measures'].includes(f.name) && (f.id ? this.$permitted(filter.id).visible : true))

      return this.filters.filter(
        f =>
          f.type === 'select' &&
          !['dimensions', 'measures'].includes(f.name) &&
          (f.id ? this.$permitted(f.id).visible : true) &&
          (f.visible === true || f.visible === undefined)
      )
    },
    baseDropdowns: function () {
      return this.filters.filter(
        f => f.type === 'select' && ['dimensions', 'measures'].includes(f.name)
      )
    },

    dateRanges: function () {
      return this.filters.filter(f => f.type === 'daterange')
    },
    isUpdateRequired () {
      if (this.isViewChanged) return true
      if (
        this.mode === 'server' &&
        (this.isFilterChanged || this.isPeriodChanged)
      )
        return true
      if (this.mode === 'client' && this.isPeriodChanged) return true

      return false
    },
    loadingTime () {
      return this.loaded && this.loadingTimeEnd
        ? this.loadingTimeEnd.diff(this.loadingTimeStart)
        : NaN
    }
  },
  created () {
    this.loadingTimeStart = moment()

    if (this.loaded === undefined)
      console.error('Required field is not specified: field "loaded": Boolean')

    this.filters.forEach(filter => {
      if (
        !filter.type ||
        !['input', 'select', 'daterange'].includes(filter.type)
      )
        console.error(
          `Required field is not specified: filter "${filter.name}", field "type": String [select|datetange]`
        )
      if (
        !filter.dataType ||
        !['datetime', 'string', 'territory', 'number'].includes(filter.dataType)
      )
        console.error(
          `Required field is not specified: filter "${filter.name}", field "dataType": String [datetime|string|number|territory]`
        )
      if (!filter.name)
        console.error(
          `Required field is not specified: filter "${filter.name}", field "name": String [datetime|string|territory]`
        )
      if (filter.type === 'select') {
        if (!filter.trackby)
          console.error(
            `Required field is not specified: filter "${filter.name}", field "trackby": String'`
          )
        if (!filter.label)
          console.error(
            `Required field is not specified: filter "${filter.name}", field "label": String'`
          )
      }
      if (!filter.title)
        console.warn(
          `Recommended field is not specified: filter "${filter.name}", "title": String'`
        )
      if (filter.type !== 'daterange' && filter.placeholder)
        console.warn(
          `REMOVE OBSOLETE PROPERTY: filter "${filter.name}", field "placeholder": String`
        )

      if (filter.async && filter.startsWith === undefined)
        console.error(
          `Required field is not specified: filter "${filter.name}", field "startsWith": Boolean`
        )
      if (filter.async && filter.loading === undefined)
        console.error(
          `Required field is not specified: filter "${filter.name}", field "loading": Boolean`
        )
    })
  },
  async mounted () {
    this.panelState = Object.assign({}, this.getPanelState(this.datasetName))

    //this.panelState = this.getPanelState(this.datasetName)

    //read selected values from storage
    this.getSelectedValuesFromStorage()

    //20200828
    //if method passed from parent component
    //do not show loading spinner for a while as different datasets using filtering panel
    //in a different ways and have to be unified at first
    if (this.loadDictionaries) {
      this.isLoading = true

      this.loadDictionaries()
        .then(!this.compact ? this.$refs.cfb.load() : true)
        .then(() => {
          this.isLoading = false

          //temporary event for Incentives and Tasks/Kanban/Gantt module. Should be replaced with 'loaded'
          // when all datasets will be updated to the same loading cycle approach
          this.$emit('dict-loaded')
          //this.$emit("loaded");
        })
    }
    //26092020 backward capability
    else if (this.panelState.isDropdownsBar) this.$emit('loaded')

    //07092020
    //backward capability
    /*
        if (!this.loadDictionaries) {

            this.isLoading = true

            if (!this.compact) await this.$refs.cfb.load()

            this.isLoading = false

            this.$emit("loaded");

        }*/

    //if (this.panelState.isDropdownsBar) this.$emit("loaded");
  },
  updated () {
    //first time, if editor was hidden
    if (this.expressionBuilder.visible) {
      this.$refs.exb.setValue(this.expressionBuilder.value)
    }
  },
  methods: {
    ...mapActions('filteringPanel', [
      'loadSettings',
      'saveState',
      'saveSelectedFilters'
    ]),
    onSearchChange (query, filter) {
      if (typeof this.debouncedSearchTimerId !== 'undefined') {
        clearTimeout(this.debouncedSearchTimerId)
      }
      this.debouncedSearchTimerId = setTimeout(() => {
        if (query.trim() === '') return

        this.$emit('async-search', {
          query: query,
          filterName: filter.name,
          startsWith: filter.startsWith
        })
      }, 1000)
    },
    setExbSelectOptions (payload) {
      this.$refs.exb.setExpressionOptions(payload)
    },
    onSearchChangeExb (event) {
      this.$emit('async-search', event)
    },
    isSearchAllowed () {
      this.formError = ''

      if (this.panelState.isCustomFiltersBar) {
        if (this.$refs.cfb.selected.data) {
          return true
        } else {
          return false
        }
      }

      //if no dimensions and measures ajusted then allow search
      if (!this.dimensions && !this.measures) return true

      //if filter panel has dimensions and measures then check if they have at least 1 selected velues
      if (
        this.selected.dimensions &&
        this.selected.measures &&
        this.selected.dimensions.length &&
        this.selected.measures.length
      ) {
        return true
      }

      this.formError = 'Please specify at least one dimension and measure'

      return false
    },
    getSelectedValuesFromStorage () {
      let selectedFilters = this.getSelectedFilters(this.datasetName)

      !this.debug ||
        console.log(
          'DP getSelectedValuesFromStorage',
          JSON.parse(JSON.stringify(selectedFilters))
        )

      let self = this

      //20201021
      //Object.keys(selectedFilters).forEach(function (key, index) {
      Object.keys(selectedFilters).forEach(function (key) {
        let f = selectedFilters[key]
        //20201022

        if (f.dataType === 'datetime' && self.$refs[f.name]) {
          self.$refs[f.name][0].setValue(f.value, {
            triggerChanged: false,
            changeTimeZone: false
          })
        }

        if (f.dataType === 'string') {
          self.selected[f.name] = f.value
          //self.$refs[f.name][0].selected = f.value
        }

        if (f.dataType === 'territory') {
          self.selected[f.name] = f.value
          //self.$refs[f.name][0].selected = f.value
        }
      })

      //find any not datetime filters
      if (
        Object.entries(selectedFilters).filter(
          k => k[1].dataType !== 'datetime'
        ).length > 0
      ) {
        this.filtersSaved2Storage = true
        //  this.$emit("change", this.selected);
      }
    },
    prepareExpressions (e) {
      this.expression.json = e ? JSON.parse(e.data) : {}

      let result = []
      if (!this.expression.json.length) return result

      this.expression.json.forEach(e => {
        let f = JSON.parse(JSON.stringify(e))
        //replace time templates with fomatted values
        if (f.fieldType === 'datetime')
          f.rules.forEach(
            r =>
              (r.selectedValue = this.$helpers
                .getDateTimeTemplateValue(r.selectedValue)
                .format('YYYY-MM-DD'))
          )

        result.push({
          field: f.fieldName,
          type: f.fieldType,
          logic: f.matchingLogic,
          rules: f.rules
        })
      })

      return result
    },
    onCustomFilterSelected (e) {
      this.selected['custom-filter'] = e

      this.$emit('custom-filter-selected', e)
    },
    onCustomFiltersLoaded () {
      console.log('onCustomFiltersLoaded')
      //if exists default filter then save it
      if (this.$refs.cfb.selected.data)
        this.selected['custom-filter'] = this.$refs.cfb.selected

      if (this.panelState.isCustomFiltersBar) {
        //07092020, backward capabiltiy, trigger autoload
        if (!this.loadDictionaries) this.$emit('loaded')
      }
    },
    onExpressionBuilderClose () {
      console.log('onExpressionBuilderClose')

      this.expressionBuilder.visible = false
      this.$refs.cfb.setMode(this.$constants.FORM_MODE.VIEW)

      //20200825
      //if (this.panelState.isCustomFiltersBar) this.$emit("change");
    },
    onExpressionBuilderSave (item) {
      this.$refs.cfb.updateOrCreateItem(item)
      this.onExpressionBuilderClose()
    },

    onCustomFilterEdit (value) {
      this.expressionBuilder.visible = true

      if (this.dimensions && this.measures) {
        let parsedValue = JSON.parse(value.data)

        let d = parsedValue.find(f => f.fieldName === 'dimensions')
        let m = parsedValue.find(f => f.fieldName === 'measures')

        if (d && m) {
          d.options = this.dimensions.options
          m.options = this.measures.options

          value.data = JSON.stringify(parsedValue)
        }
      }

      this.expressionBuilder.value = value

      if (this.$refs.exb) {
        this.$refs.exb.setValue(this.expressionBuilder.value)
      }
    },
    onCustomFilterCreate () {
      this.expressionBuilder.visible = true

      let d = []

      if (this.dimensions && this.measures) {
        d.push(
          {
            fieldTitle: 'Dimensions',
            fieldName: 'dimensions',
            fieldType: 'string',
            matchingLogic: 'All',
            rules: [
              {
                selectedValues: [],
                selectedValue: '',
                logicalOperator: '',
                values: []
              }
            ],
            options: this.dimensions.options
          },
          {
            fieldTitle: 'Measures',
            fieldName: 'measures',
            fieldType: 'string',
            matchingLogic: 'All',
            rules: [
              {
                selectedValues: [],
                selectedValue: '',
                logicalOperator: '',
                values: []
              }
            ],
            options: this.measures.options
          }
        )
      }

      this.expressionBuilder.value = {
        data: JSON.stringify(d)
      }

      if (this.$refs.exb) {
        this.$refs.exb.setValue(this.expressionBuilder.value)
      }
    },
    onResetClick () {
      this.$emit('reset')
    },
    resetFilters (
      payload = {
        resetStorage: false
      }
    ) {
      //let self = this

      this.isFilterChanged = true
      //
      //this.selected = {}

      //20201021
      for (let [index, val] of this.dateRanges.entries()) {
        //for (let val of this.dateRanges.entries()) {

        //20201112
        val._index = index

        this.$refs[val.name][0].setDefault()

        this.selected[val.name] = this.$refs[val.name][0].selected.period
      }

      //create empty object, copy period properties
      let s = {}

      for (var key in this.selected) {
        if (key.includes('period')) {
          // delete all properties except periods
          if (key.includes('period')) {
            s[key] = this.selected[key]
          }
        }
      }

      //and replace selected
      this.selected = Object.assign({}, s)

      //2 options: reset visible values or reset visible values and values stored in storage
      let data = {
        datasetName: this.datasetName,
        panelFilters: this.filters
        //  selected: this.selected
      }
      //debugger
      if (payload.resetStorage) data.selected = this.selected

      this.saveSelectedFilters(data)
      this.filtersSaved2Storage = !payload.resetStorage

      //reset visible values
      //this.selected = {}

      //this.$emit("change", this.selected);
      //this.$emit("reset");
    },
    toggleView () {
      this.panelState.isDropdownsBar = !this.panelState.isDropdownsBar
      this.panelState.isCustomFiltersBar = !this.panelState.isCustomFiltersBar
      this.isViewChanged = true
    },

    onSelectClose () {
      this.$emit('change', this.selected)
    },
    onInput () {
      this.isFilterChanged = true

      this.$emit('change', this.selected)

      let payload = {
        datasetName: this.datasetName,
        panelFilters: this.filters,
        selected: this.selected
      }

      this.saveSelectedFilters(payload)
    },

    onSelectInput () {
      this.isFilterChanged = true

      this.$emit('change', this.selected)

      let payload = {
        datasetName: this.datasetName,
        panelFilters: this.filters,
        selected: this.selected
      }

      //console.log('onSelectInput', this.selected, payload)
      this.saveSelectedFilters(payload)

      this.$forceUpdate()
    },
    onDateRangeMounted (value, name) {
      //20201022
      //debugger
      this.selected[name] = value
      //  this.$emit("change", this.selected);
    },
    onDateRangeInput () {
    
    },
    onDateRangeChange (name) {
      console.log('onDateRangeChange', this.selected, name)

      !this.debug || console.log('onDateRangeChange', name)
      //debugger
      //this.isPeriodChanged = true

      this.isPeriodChanged = this.selected[name].changed

      this.$emit('change', this.selected, name)

      let payload = {
        datasetName: this.datasetName,
        panelFilters: this.filters,
        selected: this.selected
      }

      /* 20201022 */
      //debugger
      this.saveSelectedFilters(payload)
      this.filtersSaved2Storage = true
    },
    searchData: function () {
      if (!this.isSearchAllowed()) {
        this.$form.makeToastError(this.formError)
        return
      }

      this.isViewChanged = false
      this.isFilterChanged = false
      this.isPeriodChanged = false

      this.$emit('search', this.selected)
    },

    setValue (_name, _type, _value) {
      this.selected[_name] = _value
      /* 20201022 */

      if (_type === 'daterange') {
        this.$refs[_name][0].setValue(_value, {
          triggerChanged: true,
          changeTimeZone: false
        })
      }

      if (_type === 'select') {
        //20201021
        //this.selected[filter.name] = _value
        this.selected[_name] = _value
      }

      let payload = {
        filterName: _name,
        filterType: _type,
        value: this.selected[_name]
      }

      //20220220
      this.$forceUpdate()

      this.saveState(payload)

      this.$emit('change', this.selected)

      console.log('filteringPanel.setValue.payload', payload)
      console.log('filteringPanel.setValue.selected', this.selected)
    },
    onCustomFiltersCollapseStateChage (value) {
      let payload = {
        datasetName: this.datasetName,
        panelFilters: this.filters,
        customFiltersBar: {},
        //...this.panelState
        panelState: this.panelState
      }

      payload.customFiltersBar[value.name] = value

      this.saveState(payload)
    }
  },
  watch: {
    'expressionBuilder.visible' () {
      this.$emit('state-changed')
    },
    'panelState.isUnfolded' (newVal, oldVal) {
      if (newVal !== oldVal) {
        let payload = {
          datasetName: this.datasetName,
          panelFilters: this.filters,
          //...this.panelState
          panelState: this.panelState
        }
        this.saveState(payload)
      }
    },
    'panelState.isDropdownsBar' (newVal, oldVal) {
      if (newVal !== oldVal) {
        let payload = {
          datasetName: this.datasetName,
          panelFilters: this.filters,
          //...this.panelState
          panelState: this.panelState
        }

        this.saveState(payload)
      }
    },
    'panelState.isCustomFiltersBar' (newVal, oldVal) {
      if (newVal !== oldVal) {
        let payload = {
          datasetName: this.datasetName,
          panelFilters: this.filters,
          //...this.panelState
          panelState: this.panelState
        }

        this.saveState(payload)
      }
    },
    loaded (newVal) {
      if (newVal) {
        this.loadingTimeEnd = moment()
      }
    }
  }
}
</script>

<style scoped>
.card-header {
  padding: 0rem 0.1rem;
}
</style>
