<template>

  <div class="animated fadeIn">

    <table-custom ref="dataTable" :name="`${$customTable.getCustomTableName(model)}`" :loading="dataTable.isLoading"
      :data="dataTable.dataSet" :options="dataTable.options" @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange" @column-update="onInlineColumnUpdate" @row-select="onRowSelect">
      <div slot="afterFilter">

        <b-row style="margin-left: 0px;">
          <span v-if="model.actions && model.actions.Create && !readOnly">
            <b-button-group>
              <b-button variant="outline-dark" size="sm" :disabled="dataTable.isInserting" @click="addItem()"
                title="Add item">
                <font-awesome-icon icon="plus" /> Add item
              </b-button>

              <b-button variant="outline-dark" size="sm" v-if="dataTable.isInserting" @click="cancelInsert()"
                title="Cancel">

                <font-awesome-icon icon="ban" /> Cancel
              </b-button>


            </b-button-group>
          </span>
          <span>
            <slot name="afterFilterButtons" />
          </span>
        </b-row>
      </div>

      <slot v-for="slotName in slots" :slot="slotName" :name="slotName" slot-scope="props" :row="props.row"> </slot>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button v-for="(customAction, index) in customActions" :key="`ca-${index}`" class="btn btn-secondary btn-sm"
            @click="customAction.click(props.row)" :title="customAction.title">
            <font-awesome-icon :icon="customAction.icon" />
          </button>

          <button v-if="model.actions.View" class="btn btn-primary btn-sm" @click="viewItem(props.row)" title="View">
            <font-awesome-icon icon="eye" />
          </button>

          <button v-if="model.actions.Edit && model.actions.Edit.validator(props.row) && !readOnly"
            class="btn btn-success btn-sm" @click="editItem(props.row.ID)" title="Edit">
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <!-- show delete button to everyone if action is defined in the model file OR show it to those users
          who has defined permissions (For examle WH Invoices Robert && Mark )
          If visible defined in the model file, then check if it is visible for the current row
          If visible is not defined in the model file, then check if the user has permission to delete
          If the user has permission to delete, then show the delete button
          -->
          <button v-if="
            ((
              (model.actions?.Delete?.visible ? model.actions?.Delete?.visible?.(props.row) : true)
              && model.actions.Delete && model.actions.Delete.validator(props.row)) || $permitted('delete').visible) &&
            !readOnly
          " class="btn btn-danger btn-sm" @click="deleteItem(props.row)" title="Delete">
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import modelHelpers from '@/models/helpers'

export default {
  name: 'TableWrapper',
  props: {
    parentId: {
      type: [String, Number],
      default: '',
      required: false
    },
    autoFetch: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => { },
      required: true
    },
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    slots: {
      type: Array,
      required: false,
      default: () => []
    },
    perPage: {
      type: [String, Number],
      default: 50
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    disableInlineEditing: {
      type: Boolean,
      default: false
    },

    cellClasses: {
      type: Object,
      default: () => { }
    },
    actionsMode: {
      type: String,
      default: 'router',
      validator: propValue => {
        let check = ['router', 'inline', 'event'].some(v => propValue == v)

        if (!check)
          console.error(
            `actionsMode property value '${propValue}' is invalid. Allowed only "router", "inline", "event"`
          )

        return check
      }
    },
    filterByColumn: {
      type: Boolean,
      default: true
    },
    selectableRows: {
      type: Boolean,
      default: false
    },
    customActions: {
      type: Array,
      required: false,
      default: () => { }
    }
  },
  emits: ['add', 'create', 'inserted', 'updated', 'deleted', 'loaded', 'beforeDataDisplay'],
  data: function () {
    return {
      isLoading: false,
      selectAll: false,
      selectedRows: [],
      dropdowns: {},
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          showEmpty: true,
          filterByColumn: this.filterByColumn,
          columns: this.columns,
          filterable: this.columns,
          editableColumns: this.disableInlineEditing
            ? []
            : this.model.entities
              ? this.model.entities.filter(e => e.readonly == false).map(e => e.name)
              : [],
          dropdownColumns: [],
          perPage: this.perPage,
          //showActions: false,
          showCustomActions: true,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback,
          saveEditedRecordCallback: this.saveEditedRecordCallback,
          revertEditedRecordCallback: this.revertEditedRecordCallback,
          hideSettingsBar: false,
          cellClasses: this.cellClasses,
          selectableRows: this.selectableRows,
          readOnly: this.readOnly,
          slots: this.slots,
          explicitColumnTypes: this.model.entities
            ? this.model.entities.map(e => ({
              column: e.name,
              type: e.type
            }))
            : []
        }
      },
      dataFilter: {},
      dblclick: undefined,
      serverFilter: undefined
    }
  },
  computed: {},
  created() {
    this.initialize()
  },
  mounted() { },
  methods: {
    async initialize() {

      console.log('TableWrapper.initialize', this.model)
      const errors = [];

      // Validate model name
      if (!this.model.name) {
        errors.push('Model name is undefined');

      }

      // Validate model entities
      if (!this.model.entities || !Array.isArray(this.model.entities)) {
        errors.push('Model entities are undefined or not an array');

      }

      // Validate detailsRouterName
      if ((this.model.actions?.Create || this.model.actions?.Edit || this.model.actions?.View) &&
        !this.model.detailsRouterName && this.actionsMode === 'router') {
        errors.push('Details router name is undefined when Create/Edit/View actions are declared in router mode');
      }

      // Validate services
      if (!this.model.services) {
        errors.push('Model services are undefined');

      } else {
        const requiredServices = ['fetchData', 'saveRecord', 'deleteRecord'];
        requiredServices.forEach(service => {
          if (!this.model.services[service]) {
            errors.push(`Service '${service}' is undefined`);

          }
        });
      }

      // Validate actions
      if (!this.model.actions) {
        errors.push('Model actions are undefined');

      } else {
        const requiredActions = ['Create', 'View', 'Edit', 'Delete'];
        requiredActions.forEach(action => {
          if (!this.model.actions[action]) {
            // errors.push(`Action '${action}' is undefined`);

          } else {
            // Check validators for Edit and Delete
            if ((action === 'Edit' || action === 'Delete') &&
              typeof this.model.actions[action].validator !== 'function') {
              errors.push(`Action '${action}' validator is not a function`);

            }
          }
        });
      }

      // Validate entities structure
      if (this.model.entities) {
        this.model.entities.forEach((entity, index) => {
          // Check entity name
          if (!entity.name) {
            errors.push(`Entity at index ${index} is missing a name`);

          }

          // Check readonly property
          if (!('readonly' in entity)) {
            errors.push(`Entity '${entity.name}' missing 'readonly' property`);

          }

          // Check dropdown specifics
          if (entity.type === 'dropdown') {
            if (!entity.optionsService) {
              errors.push(`Dropdown '${entity.name}' missing optionsService`);

            }
          }
        });
      }

      // Display and handle errors
      if (errors.length > 0) {
        errors.forEach(error => {
          //this.$form.makeToastError(`TableWrapper: ${error}`);
          //this.$form.msgBoxOk(error);
          console.error(`TableWrapper: ${error}`);
        });

        // Emit an error event
        this.$emit('initialization-error', errors);
        //return false;
      }

      // Existing initialization logic
      if (this.model.actions && !this.readOnly)
        this.dataTable.options.columns = [...this.dataTable.options.columns, ...['Actions']]

      this.dataTable.options.dropdownColumns = this.model.entities
        .filter(e => e.type == 'dropdown')
        .map(e => {
          return {
            name: e.name,
            options: [],
            templates: e?.templates || null
          }
        })

      for (let column of this.dataTable.options.dropdownColumns) {
        let entity = this.model.entities.find(e => e.name == column.name)

        if (!entity.optionsService) {
          console.error(`Dropdown column '${this.model.name}.${column.name}' has undefined 'optionsService' property`)
          column.options = []
        }
        else {

          column.options = await entity.optionsService()

          column.options = column.options.map(i => ({ id: i.id, label: i.name, item: i }))
        }
      }

      if (this.parentId || this.autoFetch) this.getData()

      return true;
    },

    onInlineDropdownChange(e) {

      console.log('TableWrapper.onInlineDropdownChange', e)

      e.row[e.column + '_JSON'] = { id: e.value.id, label: e.value.label }
      this.$emit('inline-dropdown-change', e)
    },
    onInlineColumnUpdate(e) {

      console.log('TableWrapper.onInlineColumnUpdate', e)
    },
    async onInlineUpdate(e) {

      console.log('TableWrapper.onInlineUpdate', e)
      let rowIndex = this.dataTable.dataSet.findIndex(i => i['ID'] === e.id);
      let row = this.dataTable.dataSet[rowIndex];

      // Update the changed field
      if (!e.value.label) {
        row[e.column] = e.value;
      } else {
        row[e.column + '_ID'] = e.value.id;
        row[e.column + '_JSON'] = JSON.stringify({ id: e.value.id, label: e.value.label });
      }

      // Validate entire row using model validator
      if (this.model.rowValidator) {
        const validate = this.model.rowValidator(row);
        if (validate.result === false) {
          this.$form.makeToastError(validate.message);
          this.getData();
          return;
        }
      }

      // Save if in non-form context
      if (!this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services?.saveRecord) {
        try {
          const response = await this.model.services.saveRecord(row);
          this.$form.makeToastInfo(response.message);
          this.getData();
        } catch (error) {
          this.$form.makeToastError(error.data.message);
        }
      }

      this.$emit('updated', this.dataTable.dataSet, row);
    },
    loadDictionaries() { },

    getDataSet() {
      return this.dataTable.dataSet
    },

    updateDataSet(payload) {
      this.dataTable.dataSet = payload
      this.$refs.dataTable.refresh()
    },
    setOrder(columnName, ascending = true) {
      this.$refs.dataTable.setOrder(columnName, ascending)
    },
    cancelInsert() {

      this.dataTable.isInserting = false

      this.$refs.dataTable.cancelInsert()



    },
    addItem() {
      if (this.actionsMode == 'router') {
        this.$router.push({
          name: this.model.detailsRouterName,
          params: {
            action: 'create'
          }
        })

        return
      }

      if (this.actionsMode == 'inline') {
        let newItem = {
          // uid: this.$helpers.uuidv4(),
          ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          ...modelHelpers.getEmptyEntitiesObjectFlatten(this.model.entities)
        }

        this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem)

        this.dataTable.isInserting = true

        return
      }

      if (this.actionsMode == 'event') {
        this.$emit('create')
        return
      }
    },
    viewItem: function (row) {
      if (this.actionsMode == 'router') {
        this.$router.push({
          name: this.model.detailsRouterName,
          params: {
            action: 'view',
            id: row.ID
          }
        })
      }

      if (this.actionsMode == 'event') {
        this.$emit('view', { row: row })
        return
      }
    },
    editItem(id) {
      if (this.actionsMode == 'router') {
        this.$router.push({
          name: this.model.detailsRouterName,
          params: {
            action: 'edit',
            id: id
          }
        });
      } else if (this.actionsMode == 'inline') {
        // Get row data
        const row = this.dataTable.dataSet.find(r => r.ID === id);

        // Add editing state to row
        this.$refs.dataTable.setRowEditing(row, true)

        // Add to vue-tables editing array
        const editableColumns = this.model.entities
          .filter(e => e.readonly === false)
          .map(e => e.name);

        editableColumns.forEach(column => {
          this.$refs.dataTable.$refs.table.$children[0].editing.push({
            id: id,
            column: column,
            originalValue: row[column]
          });
        });

        this.$forceUpdate();
      } else if (this.actionsMode == 'event') {
        this.$emit('edit', id);
      }
    },
    async saveEditedRecordCallback(editedRecord) {
      console.log('updateRecord', editedRecord)

      let self = this

      for (let entity of this.model.entities) {
        if (entity.required && !editedRecord[entity.name]) {
          this.$form.makeToastError(`Please specify the "${entity.name}" value`)
          return
        }
      }
      if (this.model.rowValidator) {
        const validate = this.model.rowValidator(editedRecord)
        if (validate.result === false) {
          this.$form.makeToastError(validate.message)
          return
        }
      }

      for (let entity of this.model.entities) {
        if (
          typeof entity.validator === 'function' &&
          !entity.validator(editedRecord).result &&
          !editedRecord[entity.name]
        ) {
          this.$form.makeToastError(`"${entity.name}" validation error: ${entity.validator(editedRecord).message}`);
          return;
        }
      }

      self.dataTable.isEditing = false

      if (
        !this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services &&
        this.model.services.saveRecord
      ) {
        console.log('TableWrapper.saveEditedRecordCallback.editedRecord', editedRecord)
        let response = await this.model.services.saveRecord(editedRecord)
        console.log('TableWrapper.saveEditedRecordCallback.response', JSON.stringify(response))
        if (!response?.status) {
          this.$form.makeToastError(`TableWrapper.saveEditedRecordCallback: wrong response format`)
          return
        }
        if (response.status == 200) this.$form.makeToastInfo(response.message)
        else this.$form.makeToastError(response.message)

        this.getData()
      }


      this.$refs.dataTable.drawTable(this.dataTable.dataSet)

      /**********/

      this.$emit('updated', editedRecord)

      return true
    },
    async revertEditedRecordCallback() {
      this.dataTable.isEditing = false

      this.getData(this.dataFilter)

      return true
    },
    async saveNewRecordCallback(newRecord) {
      console.log('newRecord', newRecord)

      let self = this

      for (let entity of this.model.entities) {
        if (entity.required && !newRecord[entity.name]) {
          this.$form.makeToastError(`Please specify the "${entity.name}" value`)
          return
        }
      }
      if (this.model.rowValidator) {
        const validate = this.model.rowValidator(newRecord)
        if (validate.result === false) {
          this.$form.makeToastError(validate.message)
          return
        }
      }

      for (let entity of this.model.entities) {
        if (
          typeof entity.validator === 'function' &&
          !entity.validator(newRecord).result &&
          !newRecord[entity.name]
        ) {
          this.$form.makeToastError(`"${entity.name}" validation error: ${entity.validator(newRecord).message}`);
          return;
        }
      }

      self.dataTable.isInserting = false

      /*20220112*/
      if (
        !this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services &&
        this.model.services.saveRecord
      ) {
        console.log('TableWrapper.saveNewRecordCallback.newRecord', newRecord)
        let response = await this.model.services.saveRecord(newRecord)
        console.log('TableWrapper.saveNewRecordCallback.response', JSON.stringify(response))
        if (!response?.status) {
          this.$form.makeToastError(`TableWrapper.saveNewRecordCallback: wrong response format`)
          return
        }
        if (response.status == 200) this.$form.makeToastInfo(response.message)
        else this.$form.makeToastError(response.message)

        this.getData()
      }


      this.$refs.dataTable.drawTable(this.dataTable.dataSet)

      /**********/

      this.$emit('inserted', newRecord)

      return true
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false

      return true
    },
    async deleteItem(row) {
      let idProp = this.$form.isCreateMode(this) ? 'uid' : 'ID'

      let confirm = await this.$form.showConfirmation(`Item #${row[idProp]} will be removed. Do you want to proceed?`)

      if (!confirm) return
      /*
      if (!this.model.services || !this.model.services.deleteRecord) {
        this.$form.makeToastError(
          `deleteRecord service is not defined for ${this.model.name}`
        );
        return;
      }
*/
      //250225 if deletion will be failed then record disappears from the table and apperars again after page reload 
      //this.dataTable.dataSet = this.dataTable.dataSet.filter(i => i[idProp] !== row[idProp])

      if (
        !this.$form.isEditMode(this) &&
        !this.$form.isCreateMode(this) &&
        this.model.services &&
        this.model.services.deleteRecord
      ) {

        this.dataTable.isLoading = true
        let response = await this.model.services.deleteRecord(row[idProp])

        if (response.status == 200) this.$form.makeToastInfo(response.message)
        else this.$form.makeToastError(response.message)

        this.getData()
      }

      this.$refs.dataTable.drawTable(this.dataTable.dataSet)

      this.$emit('deleted', row)
    },
    onRowSelect() {
      let selectedRows = this.$refs.dataTable.getSelectedRows()

      this.$emit('row-select', selectedRows)
    },

    async getData_110225(payload) {
      //console.log('TableWrapper.getData.payload', payload)
      if (payload) this.dataFilter = payload

      this.dataTable.isLoading = true

      let response = []

      if (this.parentId) {
        if (!this.dataFilter) this.dataFilter = {}

        this.dataFilter.parentId = this.parentId
      }

      try {
        //console.log('TableWrapper.getData.dataFilter', this.dataFilter)
        response = await this.model.services.fetchData(this.dataFilter)

        this.dataTable.isLoading = false

        this.dataTable.dataSet = response

        //if (response.length === 0) return;

        this.$emit('loaded', this.dataTable.dataSet.length)
      } catch (error) {
        console.log(error)
        this.dataTable.isLoading = false
        this.$form.msgBoxOk('Error occured')
      }
    },
    async getData_050325(payload) {
      if (payload) this.dataFilter = payload;

      this.dataTable.isLoading = true;
      let response = [];

      if (this.parentId) {
        if (!this.dataFilter) this.dataFilter = {};
        this.dataFilter.parentId = this.parentId;
      }

      try {

        console.log('TableWrapper.getData.dataFilter', this.dataFilter);
        response = await this.model.services.fetchData(this.dataFilter);
        this.dataTable.isLoading = false;

        // process JSON-fields
        this.dataTable.dataSet = response.map(item => {
          const newItem = { ...item };
          Object.keys(newItem).forEach(key => {
            if (key.includes('_JSON') || key.includes('JSON')) {
              newItem[key.replace(/ JSON|_JSON/g, '')] = this.$helpers.getJSONObject(newItem[key])?.label;
              newItem[key] = this.$helpers.getJSONObject(newItem[key]);
              //delete newItem[key];
            }
          });
          return newItem;
        });

        console.log('TableWrapper.getData.dataSet', this.dataTable.dataSet);
        this.$emit('loaded', this.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        this.dataTable.isLoading = false;
        this.$form.msgBoxOk('Error occured');
      }
    },
    async getData(payload) {
      if (payload) this.dataFilter = payload;

      this.dataTable.isLoading = true;
      let response = [];

      if (this.parentId) {
        if (!this.dataFilter) this.dataFilter = {};
        this.dataFilter.parentId = this.parentId;
      }

      try {
        console.log('TableWrapper.getData.dataFilter', this.dataFilter);
        response = await this.model.services.fetchData(this.dataFilter);
        this.dataTable.isLoading = false;

        // Process JSON fields
        let processedData = response.map(item => {
          const newItem = { ...item };
          Object.keys(newItem).forEach(key => {
            if (key.includes('_JSON') || key.includes('JSON')) {
              newItem[key.replace(/ JSON|_JSON/g, '')] = this.$helpers.getJSONObject(newItem[key])?.label;
              newItem[key] = this.$helpers.getJSONObject(newItem[key]);
            }
          });
          return newItem;
        });

        // Check if there are any beforeDataDisplay event listeners
        if (this.$listeners && this.$listeners.beforeDataDisplay) {
          // Create a Promise to wait for data processing
          await new Promise(resolve => {
            // Emit the event with data and a callback function
            this.$emit('beforeDataDisplay', processedData, (newData) => {
              if (newData && Array.isArray(newData)) {
                processedData = newData;
              }
              resolve();
            });
          });
        }

        // Set the processed data
        this.dataTable.dataSet = processedData;

        console.log('TableWrapper.getData.dataSet', this.dataTable.dataSet);
        this.$emit('loaded', this.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        this.dataTable.isLoading = false;
        this.$form.msgBoxOk('Error occured');
      }
    },
    disableSorting() {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.disableSorting();
      }
      return this;
    },

    sortByColumn(columnName, ascending = true) {
      this.$refs.dataTable.setOrder(columnName, ascending);
      return this;
    },


    sortDataset(columnConfig) {
      if (typeof columnConfig !== 'object') {
        console.error('sortDataset: columnConfig must be an object');
        return this;
      }

      const columnName = Object.keys(columnConfig)[0];
      const direction = columnConfig[columnName];

      const ascending = direction !== 'desc';

      return this.sortByColumn(columnName, ascending);
    }

  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {

        this.dataTable.isLoading = newVal
      }
    },
    readOnly: {
      immediate: true,
      handler(newVal) {
        this.dataTable.options.readOnly = newVal
      }
    }
  }
}
</script>

<style scoped>
::v-deep .VueTables__table {
  overflow: visible !important;
}

::v-deep .table-responsive {
  overflow: visible !important;
}


/*
::v-deep .ct-inline-select {
  width: 15em !important;
}*/
</style>
