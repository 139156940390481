import Vue from "vue";

import InlineInput from "@/components/InlineInput";

import InlineSelect from "@/components/InlineSelectMultiple";

import InlineDatePicker from "@/components/InlineDatePicker";

import InlineTimePicker from "@/components/InlineTimePicker";

import InlineSwitch from "@/components/InlineSwitch";

import FilteringPanel from "@/components/FilteringPanel";

import TableCustom from "@/components/TableCustom";

import DataViewWrapper from "@/components/DataView/DataViewWrapper";

import DetailsFormWrapper from "@/components/DataView/DetailsFormWrapper";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import FilesContainer from "@/components/FilesContainer";

import FormInput from "@/components/DataView/FormInput";

import FormSelect from "@/components/DataView/FormSelect";

import FormDateTime from "@/components/DataView/FormDateTime";

import FormTime from "@/components/DataView/FormTime";

import FormSwitch from "@/components/DataView/FormSwitch";

Vue.component("InlineInput", InlineInput);

Vue.component("InlineSelect", InlineSelect);

Vue.component("InlineDatePicker", InlineDatePicker);

Vue.component("InlineTimePicker", InlineTimePicker);

Vue.component("InlineSwitch", InlineSwitch);

Vue.component("FilteringPanel", FilteringPanel);

Vue.component("TableCustom", TableCustom);

Vue.component("DataViewWrapper", DataViewWrapper);

Vue.component("DetailsFormWrapper", DetailsFormWrapper);

Vue.component("FormSubmissionActions", FormSubmissionActions);

Vue.component("FilesContainer", FilesContainer);

Vue.component("FormInput", FormInput);

Vue.component("FormSelect", FormSelect);

Vue.component("FormDateTime", FormDateTime);

Vue.component("FormTime", FormTime);

Vue.component("FormSwitch", FormSwitch);
