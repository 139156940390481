<template>
  <td
    :tabindex="props.tabIndex"
    :class="props.classes"
    :data-label="props.column"
    style="min-height: 2.8em; max-width: 70em"
  >
    <div class="default-cell" ><vnodes :vnodes="props.content" /></div>
  </td>
</template>

<script>
export default {
  name: 'MyTableCell',
  props: ['props'],
  components: {
    vnodes: {
      functional: true,
      render: (h, ctx) =>
        typeof ctx.props.vnodes === 'object'
          ? ctx.props.vnodes
          : [ctx.props.vnodes]
    }
  }
}
</script>

<style scoped>

.default-cell {
  min-height: 2.8em;
  display: flex;
  align-items: center;
  word-break: normal;
  overflow-wrap: break-word;
  white-space: normal;
  hyphens: auto;
  line-height: normal;
}

  /*

.default-cell {
  min-height: 2.8em;
  display: flex;
  align-items: center;
  word-break: break-all;
  word-wrap: break-word !important;
  white-space: normal;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  line-height: normal;
  overflow-wrap: anywhere;
  
  
  --diplay: block;
  --text-overflow: ellipsis;
  --max-width: 20em;

  
}
  */
</style>
