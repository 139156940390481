<template>
  <div class="animated fadeIn">
    <h5>
      {{ header }}
      <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
    </h5>
    <hr />
    <slot id="content"> </slot>
    <hr />

    <form-submission-actions
      v-if="formAction !== 'preview'"
      :mode="formMode"
      :loading="{
        save: saveInProgress,
        saveAndView: saveAndViewInProgress
      }"
      :buttons-visibility="{
        previous: $customTable.getPrevKey(model, id),
        next: $customTable.getNextKey(model, id),
        save: !readOnly,
        saveAndView: !readOnly,
        edit:
          !readOnly &&
          model.actions &&
          model.actions.Edit &&
          model.actions.Edit.validator(data),

        view:
          !readOnly &&
          model.actions &&
          model.actions.View &&
          model.actions.View.validator(data)
      }"
      :custom-buttons="customButtons"
      @custom-click="
        name => {
          $emit('custom-click', name)
        }
      "
      @previous-item="
        $router.push({
          name: $route.name,
          params: {
            action: $route.params.action,
            id: $customTable.getPrevKey(model, id)
          }
        })
      "
      @next-item="
        $router.push({
          name: $route.name,
          params: {
            action: $route.params.action,
            id: $customTable.getNextKey(model, id)
          }
        })
      "
      @save="
        save('tabular').then(response =>
          response ? $router.push({ name: model.tabularRouterName }) : false
        )
      "
      @save-and-view="
        save('view').then(response =>
          response
            ? $router.push({
                name: model.detailsRouterName,
                params: { action: 'view', id: response }
              })
            : false
        )
      "
      @edit="
        $router.push({
          name: model.detailsRouterName,
          params: { action: 'edit', id: id }
        })
      "
      @back="$router.push($store.getters['router/previousRoute'])"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DetailsFormWrapper',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    model: {
      type: Object,

      default: () => {}
    },
    header: {
      type: String,
      default: 'Default header'
    },
    dictionariesCallback: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    customButtons: {
      type: Array,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data: function () {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      datasetKeys: []
    }
  },
  computed: {
    formMode () {
      return this.$form.mode(this)
    },
    formAction () {
      return this.$route.params.action || this.action
    }
  },
  created () {
    this.initialize()
  },
  mounted: function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    initialize: async function () {

      console.log('DetailsFormWrapper initialize.model',JSON.stringify(this.model)) 
      let response = undefined,
        payload = undefined

      if (this.$route.params.action !== 'create') {
        this.isLoading = true

        response = await this.model.services.fetchRecord(this.id)

        this.isLoading = false

        payload = response
      }

      this.$emit('loaded', payload)
    },

    setDefaults () {},
    save (_nextAction) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _nextAction === 'tabular'
      this.saveAndViewInProgress = _nextAction === 'view'

      this.isLoading = true

      return this.model.services
        .saveRecord(this.data)
        .then(response => {
          /*
          //TODO
          response format 
          {
            status:
            message: 
            data: {}
          }
          //Existing format: 
          {
            id:
            message:
          }
           */

          let responseId = undefined
          if (response.id) responseId = response.id
          if (response.data && response.data.id) responseId = response.data.id

          if (!responseId) console.error('Response must return record Id')

          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = responseId

          return responseId
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    }
  },
  watch: {}
}
</script>

<style></style>
