<template>
  <div>
    <inline-switch
      :id="`switch:${$helpers.str2_(properties.name)}`"
      :label="properties.label"
      :value="value"
      :readonly="properties.readonly"
      :mode="mode"
      @changed="onChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number, String],
      default: false
    },
    properties: {
      type: Object,
      required: true
    }
  },
  computed: {
    mode() {
      return this.$form.mode(this);
    }
  },
  methods: {
    onChange(id, value) {
      this.$emit('input', value);
      this.$emit('change', { id, value });
    }
  }
}
</script>
