var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.model)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "inline-update": _vm.onInlineUpdate,
      "inline-dropdown-change": _vm.onInlineDropdownChange,
      "column-update": _vm.onInlineColumnUpdate,
      "row-select": _vm.onRowSelect
    },
    scopedSlots: _vm._u([_vm._l(_vm.slots, function (slotName) {
      return {
        key: slotName,
        fn: function fn(props) {
          return _vm._t(slotName, null, {
            "row": props.row
          });
        }
      };
    }), {
      key: "custom-actions",
      fn: function fn(props) {
        var _vm$model$actions, _vm$model$actions$Del, _vm$model$actions2, _vm$model$actions2$De, _vm$model$actions2$De2;
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_vm._l(_vm.customActions, function (customAction, index) {
          return _c('button', {
            key: "ca-".concat(index),
            staticClass: "btn btn-secondary btn-sm",
            attrs: {
              "title": customAction.title
            },
            on: {
              "click": function click($event) {
                return customAction.click(props.row);
              }
            }
          }, [_c('font-awesome-icon', {
            attrs: {
              "icon": customAction.icon
            }
          })], 1);
        }), _vm.model.actions.View ? _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "View"
          },
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1) : _vm._e(), _vm.model.actions.Edit && _vm.model.actions.Edit.validator(props.row) && !_vm.readOnly ? _c('button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "title": "Edit"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1) : _vm._e(), (((_vm$model$actions = _vm.model.actions) !== null && _vm$model$actions !== void 0 && (_vm$model$actions$Del = _vm$model$actions.Delete) !== null && _vm$model$actions$Del !== void 0 && _vm$model$actions$Del.visible ? (_vm$model$actions2 = _vm.model.actions) === null || _vm$model$actions2 === void 0 ? void 0 : (_vm$model$actions2$De = _vm$model$actions2.Delete) === null || _vm$model$actions2$De === void 0 ? void 0 : (_vm$model$actions2$De2 = _vm$model$actions2$De.visible) === null || _vm$model$actions2$De2 === void 0 ? void 0 : _vm$model$actions2$De2.call(_vm$model$actions2$De, props.row) : true) && _vm.model.actions.Delete && _vm.model.actions.Delete.validator(props.row) || _vm.$permitted('delete').visible) && !_vm.readOnly ? _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "title": "Delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e()], 2)]);
      }
    }], null, true)
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_c('b-row', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_vm.model.actions && _vm.model.actions.Create && !_vm.readOnly ? _c('span', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "size": "sm",
      "disabled": _vm.dataTable.isInserting,
      "title": "Add item"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Add item ")], 1), _vm.dataTable.isInserting ? _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "size": "sm",
      "title": "Cancel"
    },
    on: {
      "click": function click($event) {
        return _vm.cancelInsert();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "ban"
    }
  }), _vm._v(" Cancel ")], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('span', [_vm._t("afterFilterButtons")], 2)])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }