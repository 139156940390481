import Vue from "vue";
import Store from "../store";
import Router from "vue-router";
import { ifNotAuthenticated, ifAuthenticated } from "./helpers";
import { MODULES } from "@/shared/constants";

import relationshipAdministrative from "./relationship-administrative";
import trackTime from "./track-time";
import payroll from "./payroll";
import knowledgeBase from "./knowledge-base";
import reimbursements from "./reimbursements";
import companyCardReceipts from "./company-card-receipts";
import accountTouchpoints from "./account-touchpoints";
import reports from "./reports";
import reportsAdmin from "./reports-admin";
import distribution from "./distribution";
import salesAdministrative from "./sales-administrative";
import relationshipManagement from "./relationship-management";
import incentives from "./incentives";
import posAndSamples from "./pos-and-samples";
import projectManagement from "./project-management";
import documentsManagement from "./documents-management";
import markdownDocuments from "./markdown-documents";
import legacyReporting from "./legacy-reporting";
import settings from "./settings";
import userManagement from "./user-management";
import inventory from "./inventory";
import production from "./production.router";
import warehouse from "./warehouse.router";
import benefits from "./benefits.router";

Vue.use(Router);

Router.prototype.open = function(routeObject) {
  const { href } = this.resolve(routeObject);
  window.open(href, "_blank");
};

let router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  beforeCreate: function() {},
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login"),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("@/views/Login"),
      beforeEnter: ifAuthenticated
    },
    {
      path: "/",
      name: "",
      redirect: "/home",
      component: () => import("@/containers/DefaultContainer"),
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: "",
          name: "",
          redirect: "/home",
          component: () => import("@/views/Home"),

          beforeEnter: ifAuthenticated,
          meta: { hideFromMenu: true }
        },
        {
          path: "/home",
          name: "Home (Dashboard)",
          component: () => import("@/views/Home"),
          beforeEnter: ifAuthenticated,
          meta: { icon: "icon-home" }
        },

        {
          path: "/access-denided/:id",
          name: "Access denided",
          props: true,
          component: () => import("@/views/AccessDenided"),
          beforeEnter: ifAuthenticated,
          meta: { hideFromMenu: true }
        },
        {
          path: "/search-results/:query",
          name: "Search results",
          component: () => import("@/views/SearchResults"),
          props: true,
          beforeEnter: ifAuthenticated,
          meta: { module: MODULES.GlobalSearch.id, hideFromMenu: true }
        },
        {
          path: "/chart-details",
          name: "Chart drilldown",
          component: () => import("@/components/ChartDetails"),
          props: true,
          meta: {
            label: "",
            hideFromMenu: true
          },
          beforeEnter(to, from, next) {
            ifAuthenticated(to, from, next);

            to.meta.label =
              Store.getters["drilldown.parent"] +
              " / " +
              Store.getters["drilldown.description"];

            next();
          }
        },
        {
          name: "HR RESOURCES",
          path: "",
          meta: {
            title: true
          }
        },
        { ...trackTime },
        { ...payroll },
        { ...knowledgeBase },
        { ...benefits },

        {
          name: "EXPENSES",
          path: "",
          meta: {
            title: true
          }
        },
        { ...reimbursements },
        { ...companyCardReceipts },
        {
          name: "TEAM ACTIVITY",
          path: "",
          meta: {
            title: true
          }
        },
        { ...accountTouchpoints },
        { ...reports },
        { ...reportsAdmin },
        {
          name: "SALES REPORTING",
          path: "",
          meta: {
            title: true
          }
        },
        { ...warehouse },
        { ...distribution },
        { ...salesAdministrative },
        {
          name: "RELATIONSHIP MANAGEMENT",
          path: "",
          meta: {
            title: true
          }
        },
        { ...relationshipManagement },
        { ...relationshipAdministrative },
        { ...incentives },
        { ...inventory },
        { ...production },
        { ...posAndSamples },
        {
          name: "PROJECT MANAGEMENT",
          path: "",
          meta: {
            title: true
          }
        },
        { ...projectManagement },
        {
          name: "DOCUMENT MANAGEMENT",
          path: "",
          meta: {
            title: true
          }
        },
        { ...documentsManagement },
        {
          name: "LEGACY REPORTING",
          path: "",
          meta: {
            title: true
          }
        },
        { ...legacyReporting },
        {
          name: "SETTINGS",
          path: "",
          meta: {
            title: true
          }
        },
        { ...settings },
        { ...userManagement }
      ]
    }
  ]
});

import userSettingsService from "@/services/user-settings.service";

router.beforeEach(async (to, from, next) => {
  if (Store.getters.isAuthenticated) {
    //save settings every N minutes
    userSettingsService.saveSettings();

    Vue.prototype.$track({ from: from, to: to });
  }

  next();
});

export default router;
