<template>
  <div >
  
  <inline-select
    :id="`select:${$helpers.str2_(properties.name)}`"
    :value="value"
    :label="properties.name"
    :readonly="properties.readonly"
    :options="options"
    :allow-empty="properties.allowEmpty"
    :multiple="properties.multiple"
    :mode="mode"
    @changed="onChange"
    :required="properties.required"
  />
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, Array, String],
      default: () => {}
    },

    properties: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      options: []
    }
  },
  computed: {
    mode () {
      return this.properties.mode || this.$form.mode(this)
    }
  },
  created () {},
  async mounted () {
    if (!this.properties.optionsService || typeof this.properties.optionsService !== 'function')
      console.error(
      `FormSelect '${this.properties.name}' has invalid or undefined 'optionsService' property`
      )


    this.fetchOptions()
  },
  methods: {
    async fetchOptions () {
      
      let response = await this.properties.optionsService()

      if (this.properties.customOptionsLayout)  this.options = response;
      else
      this.options = response.map(i => ({
        id: i.id,
        label: i.name
      }))

      console.log('FormSelect.fetchOptions', this.options)
    },

    onChange (id, value) {
      this.$emit('input', value)

      this.$emit('change', { id, value })
    }
  }
}
</script>

<style></style>
